/*!
    Version: 1.0.0
    Author: Arunachalmoorthy Sethuraja
    Website: https://workativ.com/
*/

/* Workativ Assistant | Cognitive Automation Solutions */

/************ TABLE OF CONTENTS ***************
1.  Fonts
2.  Reset
3.  Global Settings
4.  Main Header
5.  Main Slider
6.  Dialog Editor
7.  Benefits
8.  How it Works
9.  Try Now
10. Signup Free Demo
11. Main Footer
12. Design Transform
13. Explore our best features
14. Capabilities
15. Interations
16. Know How Cognidesk
17. Project Details
18. Future Communication
19. AI Adoption Space
20. Internet of Everything
21. Killer AI Project
22. Space Explorations
23. Statistics Boggle
24. Whooping Percent
25. Privacy Policy
26. Terms & Conditions
27. Home Page Responsive for Landscape and Portrait
28. Features Page Responsive for Landscape and Portrait
29. Blogs Page Responsive for Landscape and Portrait
30. Blogs Details Page Responsive for Landscape and Portrait
31. Privacy Policy Page Responsive for Landscape and Portrait
32. Blog Page Responsive for Landscape and Portrait
33. Top Header


**********************************************/

@font-face {
  font-family: Montserrat-Bold;
  src: url("../fonts/Montserrat-Bold.ttf");
  font-display: swap;
}

@font-face {
  font-family: Montserrat-SemiBold;
  src: url("../fonts/Montserrat-SemiBold.ttf");
  font-display: swap;
}

@font-face {
  font-family: "Roboto-Bold";
  src: url("../fonts/Roboto-Bold.woff2") format('woff2'),
       url("../fonts/Roboto-Bold.woff") format('woff');
  font-display: swap;
}

@font-face {
  font-family: "Roboto-Medium";
  src: url("../fonts/Roboto-Medium.woff2") format('woff2'),
       url("../fonts/Roboto-Medium.woff") format('woff');
  font-display: swap;
}

@font-face {
  font-family: "Roboto-Regular";
  src: url("../fonts/Roboto-Regular.woff2") format('woff2'),
       url("../fonts/Roboto-Regular.woff") format('woff');
  font-display: swap;
}

/*** 

====================================================================
	Reset
====================================================================

 ***/

/*** 

====================================================================
	Global Settings
====================================================================

 ***/

body {
  font-family: Montserrat-Regular;
  font-size: 14px;
  color: #505050;
  line-height: 1.6em;
  font-weight: 400;
  background: #fff;
}

a {
  text-decoration: none;
  cursor: pointer;
}

a:hover,
a:focus,
a:visited {
  text-decoration: none;
  outline: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: Montserrat-Regular;
  font-weight: normal;
  margin: 0px;
  background: none;
}

input,
button,
select,
textarea {
  font-family: Montserrat-Regular;
}

/*** 

====================================================================
	Main Header
====================================================================

    ***/

.header-logo ul li a {
  font-size: 15px;
  color: #1f3750 !important;
  font-family: "Roboto-Medium";
  /* text-transform: lowercase; */
  padding: 0;
  line-height: 1.25;
}

/* .header-logo ul li {
  padding: 10px;
  cursor: pointer;
  padding-left:0px;
} */

.hamburger {
  position: absolute;
  right: 20px;
  top: 20px;
  cursor: pointer;
}

.hamburger svg {
  font-size: 25px;
  color: #494949;
}

.nav-offcanvas {
  width: 300px;
  background: linear-gradient(135deg, #ffffff 0%, #ffffff 100%);
  height: 100vh;
  position: fixed;
  overflow-x: auto;
  z-index: 2000;
  /* padding: 50px 0; */
  top: 0;
  right: -1200px;
  transition: 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94);
  box-shadow: 0px 10px 27px 0px rgba(0, 0, 0, 0.15);
}

.nav-offcanvas .close {
  position: absolute;
  right: 20px;
  top: 20px;
  outline: 0;
  opacity: 1;
  background: transparent;
  border: none;
  box-shadow: none;
  cursor: pointer;
  transition: 0.3s ease;
}

.nav-offcanvas .close i {
  font-size: 15px;
  color: #333333;
  text-shadow: none;
  position: relative;
  top: -8px;
  right: 5px;
}

.nav-offcanvas .close:hover {
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
}

.nav-offcanvas-menu ul {
  margin-bottom: 0;
  padding: 0px 40px;
  list-style: none;
}

.nav-offcanvas-menu ul li a {
  color: #333333;
  font-size: 16px;
  font-weight: 300;
  padding: 10px 40px;
  display: block;
  text-decoration: none;
  position: relative;
  z-index: 1;
  transition: 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0.05s;
}

.nav-offcanvas-menu ul li a:hover {
  -webkit-transform: translateX(10px);
  transform: translateX(10px);
}

.nav-offcanvas.open {
  right: 0;
}

.offcanvas-overlay {
  position: fixed;
  top: 0;
  width: 100%;
  height: 100vh;
  background: rgba(0, 0, 0, 0.2);
  z-index: 1999;
  transition: 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94);
  visibility: hidden;
  opacity: 0;
}

.offcanvas-overlay.on {
  opacity: 1;
  visibility: visible;
}

.free_trial {
  background: #1757dc;
  color: #fff;
  border-radius: 6px;
  width: auto;
  text-align: center;
  padding: 2px 15px 2px 15px;
}

.navbar-nav .free_trial a {
  color: #fff !important;
  font-size: 14px;
  padding: 0px !important;
}

.nav-link.nav_link_active {
  color: rgb(23, 99, 255) !important;
}

.nav-offcanvas-menu ul li .nav_link_active {
  color: rgb(23, 99, 255) !important;
}

.slider_buton ul {
  padding-left: 0;
}

.slider_buton ul li a {
  text-transform: uppercase;
}

.download-broucher {
  font-size: 14px;
  font-family: "Roboto-Bold";
  color: #2468f6;
}

.hamburger svg {
  font-size: 25px;
  color: #494949;
  display: none;
}

.header-logo .free_trial {
  background: #2e6dfa;
  color: #fff;
  border-radius: 0px;
  width: 112px;
  text-align: center;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  outline: 0;
  border: none;
  cursor: pointer;
  transition: transform 0.5s ease;
}

.header-logo .free_trial:hover {
  transform: scale(1.012);
}

.header-logo img {
  width: 140px;
}

.header-logo {
  padding: 0px !important;
  width: 100%;
}

.sticky {
  position: fixed;
  z-index: 9999;
  width: 100%;
  background-color: #fff;
  top: 0;
  box-shadow: 0 3px 5px -5px #ccc;
  height: 80px;
  padding-top: 0px;
  transition: transform 0.5s ease;
  display: flex;
  align-items: center;
  animation: smoothScroll 0.5s forwards;
}
@keyframes smoothScroll {
  0% {
    transform: translateY(-20px);
  }
  100% {
    transform: translateY(0px);
  }
}

.sticky .header-logo img {
  width: 140px;
}
.header.sticky {
  background: #fff !important;
}

/* .sticky .header-logo {
  margin: 15px 0px;
} */

.navbar-light .navbar-nav .nav-link:focus,
.navbar-light .navbar-nav .nav-link:hover {
  color: rgb(23, 99, 255) !important;
}

.navbar-light .navbar-nav .nav-link.trial-white:focus,
.navbar-light .navbar-nav .nav-link.trial-white:hover {
  color: rgb(255, 255, 255) !important;
}

/*** 

====================================================================
	Main Slider
====================================================================

	***/

.main-slider-left h1 {
  font-family: "Roboto-Medium";
  font-size: 40px;
  color: #000000;
  letter-spacing: -1.5;
  margin-bottom: 10px;
}

.main-slider-left {
  padding: 4em 0;
}

.main-slider-right img {
  width: 100%;
}

.main-slider-left p {
  font-size: 18px;
  color: #485a63;
  font-family: "Roboto-Regular";
  line-height: 30px;
  margin-bottom: 20px;
}

.main-slider {
  padding: 0px 0px 0px 0px;
}

.free-trial {
  font-family: "Roboto-Medium";
  background: #2e6dfa;
  color: #fff;
  border-radius: 0px;
  width: auto;
  text-align: center;
  padding: 2px 15px 2px 15px;
  transition: transform 0.5s ease;
}

.free-trial a {
  color: #fff !important;
  font-size: 16px;
}

.main-slider-button ul {
  padding-left: 0;
}

/* .main-slider-button ul li a {
  text-transform: uppercase;
} */

.main-download-broucher {
  font-size: 14px;
  font-family: "Roboto-Bold";
  color: #2468f6;
}

.main-slider-button ul li {
  display: inline-block;
}

.main-slider-button ul li a {
  color: #2468f6;
}

.main-slider-button ul li a:hover {
  color: #1757dc;
}

.main-slider-left p a {
  color: #1757dc;
}

.main-slider-right img {
  padding: 8em 0;
}

/*** 

====================================================================
	Dialog Editor
====================================================================

	***/

.dialog-editor-content h5 {
  color: #333333;
  font-size: 24px;
  font-family: "Roboto-Bold";
  text-align: center;
  margin-bottom: 20px;
}

.dialog-editor-content p {
  color: #485a63;
  font-size: 16px;
  font-family: "Roboto-Regular";
  line-height: 30px;
  text-align: center;
  margin-bottom: 40px;
  padding: 0 10em;
}

.dialog-editor-list ul {
  padding-left: 0px;
}

.dialog-editor-list ul li {
  display: inline-block;
  margin: 0 28px;
}

.dialog-editor-list ul li img {
  width: 160px;
}

.dialog-editor-list {
  text-align: center;
  margin-bottom: 45px;
}

.dialog-editor .carousel-inner img {
  box-shadow: 10px 10px 9px 0px rgba(228, 233, 236, 0.75);
  -webkit-box-shadow: 10px 10px 9px 0px rgba(228, 233, 236, 0.75);
  -moz-box-shadow: 10px 10px 9px 0px rgba(228, 233, 236, 0.75);
  margin-bottom: 40px;
  width: 100%;
  float: left;
}

.dialog-editor .carousel-indicators {
  position: relative;
}

.dialog-editor .carousel-indicators li {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background-color: #2889e8;
  margin-bottom: 30px;
}

.our-technology-partners-content h5 {
  color: #adbec6;
  font-size: 16px;
  font-family: "Roboto-Regular";
  text-transform: uppercase;
  text-align: center;
  margin-bottom: 40px;
  margin-top: 70px;
  letter-spacing: 3px;
}

.carousel-fade .carousel-item {
  opacity: 0;
  transition: opacity 0.75s ease-in-out;
  background-color: rgba(255, 255, 255, 0.6784313725490196);
}

.carousel-fade .carousel-item.active,
.carousel-fade .carousel-item-next.carousel-item-left,
.carousel-fade .carousel-item-prev.carousel-item-right {
  opacity: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}

.carousel-fade .active.carousel-item-left,
.carousel-fade .active.carousel-item-right {
  opacity: 0;
}

.carousel-item-next,
.carousel-item-prev,
.carousel-item.active {
  display: flex !important;
  align-items: center !important;
  justify-content: center;
}

/* .carousel-fade .carousel-item-next,
.carousel-fade .carousel-item-prev,
.carousel-fade .carousel-item.active,
.carousel-fade .active.carousel-item-left,
.carousel-fade .active.carousel-item-prev {
  transform: translateX(0);
  transform: translate3d(0, 0, 0);
} */

/*** 

====================================================================
	Benefits
====================================================================

	***/

.benefits-workplace {
  margin-top: 100px;
}

.benefits-works-content {
  text-align: center;
}

.benefits-works-content img {
  margin-bottom: 40px;
}

.benefits-workplace h5 {
  font-size: 16px;
  font-weight: 500;
  text-align: center;
  color: #adbec6;
  font-family: "Roboto-Regular";
  margin-bottom: 40px;
  text-transform: uppercase;
  letter-spacing: 3px;
  cursor: pointer;
}

.benefits-works-content h3 {
  color: #333333;
  font-size: 32px;
  font-family: "Roboto-Bold";
  text-align: center;
  margin-bottom: 40px;
}

.benefits-works-content p {
  color: #485a63;
  font-size: 16px;
  font-family: "Gordita-Regular";
  line-height: 30px;
  text-align: center;
  margin-bottom: 75px;
  padding: 0 13em;
}

.benefits-workplace-content h3 {
  color: #333333;
  font-size: 24px;
  margin-bottom: 25px;
  font-family: "Gordita-Bold";
  line-height: 1.3;
}

.benefits-workplace-content p {
  color: #485a63;
  font-size: 16px;
  font-family: "Gordita-Regular";
  line-height: 30px;
  margin-bottom: 60px;
}

.benefits-workplace-content img {
  margin-bottom: 20px;
  width: 100px;
  height: 100px;
}

/*** 

====================================================================
	How it Works
====================================================================

	***/

.how-it-works-content img {
  width: 100%;
  margin-bottom: 0px;
}

.how-it-works h5 {
  font-size: 16px;
  font-weight: 500;
  text-align: center;
  color: #adbec6;
  font-family: "Roboto-Regular";
  margin-bottom: 40px;
  text-transform: uppercase;
  letter-spacing: 3px;
  cursor: pointer;
}

.how-it-works-content h3 {
  color: #333333;
  font-size: 24px;
  margin-bottom: 25px;
  font-family: "Roboto-Bold";
  line-height: 1.3;
}

.how-it-works-content p {
  color: #485a63;
  font-size: 16px;
  font-family: "Roboto-Regular";
  line-height: 30px;
}

.how-it-works-content.image-center {
  text-align: center;
}

.how-it-works-content.image-center img {
  width: auto;
  height: auto;
}

.how-it-works {
  padding: 40px 0 100px 0;
}

.how-it-works-bg {
  background-image: url(../images/how-it-works-bg.png);
  background-repeat: no-repeat;
  background-position: top;
  padding: 150px 0 0;
}

.how-it-pointer img {
  width: 4%;
  margin-bottom: 30px;
}

.how-it-pointer {
  text-align: center;
}

.left-works {
  margin-top: -40px;
}

.right-works {
  margin-top: -40px;
}

.center-works {
  margin-top: -140px;
}

.how-it-works-content img {
  margin-bottom: 60px;
  width: 120px;
  height: 120px;
}

.center-works img {
  margin-bottom: 160px;
}

/*** 

====================================================================
	Try Now
====================================================================

	***/

.try-now-content h2 {
  font-family: "Roboto-Bold";
  font-size: 40px;
  color: #333333;
  letter-spacing: 1.5;
  margin-bottom: 30px;
  text-align: center;
  padding: 0 2em;
}

.try-now-content p {
  font-size: 16px;
  color: #485a63;
  font-family: "Roboto-Regular";
  line-height: 30px;
  margin-bottom: 30px;
  text-align: center;
  padding: 0 11em;
}

.try-now-content img {
  width: 100%;
  margin-bottom: 35px;
}

.try-now-button ul {
  padding-left: 0;
}

.try-now-button ul li {
  display: inline-block;
}

.free-try-now {
  background: #1757dc;
  color: #fff;
  border-radius: 6px;
  width: auto;
  text-align: center;
  padding: 2px 15px 2px 15px;
}

.free-try-now a {
  color: #fff !important;
  font-size: 16px;
}

.try-now-button ul li a {
  text-transform: uppercase;
}

.try-now-button {
  text-align: center;
}

.try-now-content h5 {
  color: #adbec6;
  font-size: 16px;
  font-family: "Roboto-Regular";
  text-transform: uppercase;
  text-align: center;
  margin-bottom: 30px;
  letter-spacing: 3px;
}

/*** 

====================================================================
	Signup Free Demo
====================================================================

	***/

.signup-left-demo h2 {
  font-family: "Roboto-Bold";
  font-size: 40px;
  color: #333333;
  letter-spacing: -1.5;
  margin-bottom: 30px;
}

.signup-right-demo img {
  width: 100%;
}

.signup-left-demo p {
  font-size: 16px;
  color: #485a63;
  font-family: "Roboto-Regular";
  line-height: 30px;
  margin-bottom: 30px;
}

.signup-demo {
  padding: 180px 0;
}

.free-trial:hover {
  color: #fff;
  border-radius: 0px;
  width: auto;
  text-align: center;
  padding: 2px 15px 2px 15px;
  transform: scale(1.012);
}

.signup-slider-button ul {
  padding-left: 0;
}

.signup-slider-button ul li a {
  text-transform: uppercase;
}

.download-broucher {
  font-size: 14px;
  font-family: "Roboto-Bold";
  color: #2468f6;
}

.signup-slider-button ul li {
  display: inline-block;
}

.signup-slider-button ul li a {
  color: #1757dc;
}

/*** 

====================================================================
	Main Footer
====================================================================

	***/

.main-footer {
  background: rgb(246, 251, 252);
  width: 100%;
  float: left;
}

.text-center {
  text-align: center;
}

.footer-border {
  border-top: #d0d8db 1.5px solid;
}

.text-footer {
  color: #485a63;
  font-family: "Roboto-Regular";
  font-size: 14px;
  text-transform: uppercase;
  padding: 30px 0 30px 0;
}

.footer-content h3 {
  font-family: "Roboto-Bold";
  color: #485a63;
  font-size: 16px;
  text-transform: uppercase;
  padding-left: 40px;
  margin-bottom: 40px;
}

.widget-content ul li {
  list-style-type: none;
  margin-bottom: 15px;
}

.widget-content ul li a {
  list-style-type: none;
  font-size: 14px;
  color: #485a63;
  text-transform: uppercase;
  text-decoration: none;
}

.footer-content {
  padding: 90px 0 56px 0;
}

.main-footer .social {
  position: relative;
  margin-left: 33px;
  margin-bottom: 15px;
}

.main-footer .social a {
  position: relative;
  display: inline-block;
  width: 26px;
  height: 26px;
  line-height: 22px;
  padding: 3px;
  text-align: center;
  color: #485a63;
  background: none;
  margin-right: 3px;
  text-decoration: none;
}

.footer-content a img {
  width: 220px;
  padding-left: 40px;
  margin-bottom: 30px;
}

/*** 

====================================================================
	Design Transform
====================================================================

    ***/

.design-transform {
  padding: 80px 0 100px 0;
}

.design-transform h1 {
  line-height: 1.29;
  letter-spacing: -0.2px;
  text-align: center;
  color: #333333;
  font-family: "Roboto-Bold";
  font-size: 70px;
  padding: 0 40px;
  margin-bottom: 40px;
}

.design-transform p {
  font-family: "Roboto-Regular";
  font-size: 20px;
  line-height: 1.67;
  text-align: center;
  color: #485a63;
  margin-bottom: 0;
}

/*** 

====================================================================
	Explore our best features
====================================================================

    ***/

.explore-best-fetures {
  text-align: center;
}

.explore-best-fetures h5 {
  font-size: 16px;
  font-weight: 500;
  text-align: center;
  color: #adbec6;
  font-family: "Roboto-Regular";
  margin-bottom: 40px;
  text-transform: uppercase;
  letter-spacing: 3px;
  cursor: pointer;
}

.explore-best-fetures img {
  margin-bottom: 40px;
}

.features_tab_inner {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding-bottom: 0px;
}

.features_tab_inner .features_tab_left {
  width: 40%;
  float: left;
}

.features_tab_inner .features_tab_left .features_tab {
  max-width: 620px;
  margin-right: 70px;
  border: 0px;
  position: relative;
  width: auto;
  left: 0;
  bottom: 0;
  text-align: left;
  display: block;
  margin-left: 15em;
  padding: 1em 0;
}

.features_tab_inner .features_tab_left .features_tab li {
  margin: 0px 0px 10px;
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 25px 25px 25px 25px;
  height: 100%;
  text-indent: inherit;
  border-radius: 5px;
  -webkit-transition: all 0.2s linear;
  -o-transition: all 0.2s linear;
  transition: all 0.2s linear;
  border: 0px solid #eee;
  position: relative;
}

.features_tab_inner .features_tab_left .features_tab li .tab_icon {
  position: absolute;
  left: 30px;
  top: 30px;
}

.features_tab_inner .features_tab_left .features_tab li .heading {
  font-size: 18px;
  line-height: 28px;
  color: #555555;
  font-weight: 600;
  display: block;
  position: relative;
  padding-bottom: 5px;
}

.features_tab_inner .features_tab_left .features_tab li .summary {
  font-size: 15px;
  line-height: 25px;
  color: #555555;
}

.features_tab_inner .features_tab_left .features_tab li.active,
.features_tab_inner .features_tab_left .features_tab li:hover,
.features_tab_inner .features_tab_left .features_tab li:focus {
  background-color: white;
  /* -webkit-box-shadow: 0px 8px 26px 2px rgba(0, 0, 0, 0.05);
    box-shadow: 0px 8px 26px 2px rgba(0, 0, 0, 0.05); */
}

.features_tab_inner .features_tab_right {
  width: 60%;
  float: left;
}

.features_tab_inner .features_tab_right .carousel-inner {
  padding-right: 10px;
  text-align: center;
  -webkit-transition: all 0.625s cubic-bezier(0, 0, 0.2, 1);
  -o-transition: all 0.625s cubic-bezier(0, 0, 0.2, 1);
  transition: all 0.625s cubic-bezier(0, 0, 0.2, 1);
  position: relative;
  padding-left: 10px;
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.features_tab_inner .features_tab_right .carousel-inner .carousel-item {
  opacity: 0;
  left: 30px;
  -webkit-transition: all 0.625s cubic-bezier(0, 0, 0.2, 1);
  -o-transition: all 0.625s cubic-bezier(0, 0, 0.2, 1);
  transition: all 0.625s cubic-bezier(0, 0, 0.2, 1);
  padding-bottom: 0px;
  padding-top: 0px;
}

.features_tab_inner .features_tab_right .carousel-inner img {
  -webkit-box-shadow: 0px 0px 23px -4px rgba(0, 0, 0, 0.25);
  box-shadow: 0px 0px 23px -4px rgba(0, 0, 0, 0.25);
  position: relative;
  -webkit-transition: all 0.625s cubic-bezier(0, 0, 0.2, 1);
  -o-transition: all 0.625s cubic-bezier(0, 0, 0.2, 1);
  transition: all 0.625s cubic-bezier(0, 0, 0.2, 1);
  max-width: 100%;
}

.features_tab_inner .features_tab_right .carousel-inner .active {
  opacity: 1;
  visibility: visible;
  display: block;
  left: 0;
  -webkit-transition: all 0.6s linear;
  -o-transition: all 0.6s linear;
  transition: all 0.6s linear;
}

.features_tab_inner .features_tab_right .carousel-inner .active img {
  left: 0;
  opacity: 1;
}

/*** 

====================================================================
	Capabilities
====================================================================

	***/

.capabilities-separator {
  text-align: center;
}

.capabilities-workplace h5 {
  font-size: 16px;
  font-weight: 500;
  text-align: center;
  color: #adbec6;
  font-family: "Roboto-Regular";
  margin-bottom: 40px;
  text-transform: uppercase;
  margin-top: 100px;
  letter-spacing: 3px;
  cursor: pointer;
}

.capabilities-workplace .capabilities-content img {
  margin-bottom: 20px;
  width: 100px;
  height: 100px;
}

.capabilities-workplace img {
  margin-bottom: 40px;
}

.capabilities-content h3 {
  color: #333333;
  font-size: 24px;
  margin-bottom: 20px;
  font-family: "Roboto-Bold";
  line-height: 1.3;
  text-align: left;
}

.capabilities-content p {
  color: #485a63;
  font-size: 16px;
  font-family: "Roboto-Regular";
  line-height: 30px;
  margin-bottom: 100px;
  text-align: left;
}

/*** 

====================================================================
	Interations
====================================================================

    ***/

.interations-content h3 {
  color: #263238;
  font-size: 40px;
  font-family: "Roboto-Bold";
  text-align: center;
  margin-bottom: 20px;
}

.interations-content p {
  color: #485a63;
  font-size: 18px;
  font-family: "Roboto-Regular";
  line-height: 30px;
  text-align: center;
  padding: 0 12em;
  margin-bottom: 0;
}

.interations-separator h5 {
  font-size: 16px;
  font-weight: 500;
  text-align: center;
  color: #adbec6;
  font-family: "Roboto-Regular";
  margin-bottom: 40px;
  text-transform: uppercase;
  letter-spacing: 3px;
  cursor: pointer;
}

.interations-separator img {
  margin-bottom: 40px;
}

.interations-separator-center {
  text-align: center;
}

.integration-project-wrap {
  padding: 70px 0 100px 0;
}

.integration-content-wrap ul li {
  display: inline-block;
  padding: 0 0.5px;
}

.integration-jira {
  background-color: #eceff1;
  padding: 20px 30px;
  border-radius: 50%;
  width: 100px;
  height: 100px;
  border: 1px solid #e4e4e4;
}

.integration-jira p {
  font-size: 8px;
  font-family: "Roboto-Regular";
  text-align: center;
  color: #b0bec5;
  text-transform: uppercase;
  margin-top: 5px;
  margin-bottom: 0;
}

.integration-jira img {
  width: 100%;
}

.integration-amazon {
  background-color: #eceff1;
  padding: 20px 30px;
  border-radius: 50%;
  width: 140px;
  height: 140px;
  border: 1px solid #e4e4e4;
}

.integration-amazon p {
  font-size: 8px;
  font-family: "Roboto-Regular";
  text-align: center;
  color: #b0bec5;
  text-transform: uppercase;
  margin-top: 5px;
  margin-bottom: 0;
}

.integration-amazon img {
  width: 100%;
}

.integration-slack {
  background-color: #eceff1;
  padding: 22px 30px;
  border-radius: 50%;
  width: 120px;
  height: 120px;
  border: 1px solid #e4e4e4;
}

.integration-slack p {
  font-size: 8px;
  font-family: "Roboto-Regular";
  text-align: center;
  color: #b0bec5;
  text-transform: uppercase;
  margin-top: 10px;
  margin-bottom: 0;
}

.integration-slack img {
  width: 100%;
}

.integration-atlassian {
  background-color: #eceff1;
  padding: 15px 22px;
  border-radius: 50%;
  width: 100px;
  height: 100px;
  border: 1px solid #e4e4e4;
}

.integration-atlassian p {
  font-size: 8px;
  font-family: "Roboto-Regular";
  text-align: center;
  color: #b0bec5;
  text-transform: uppercase;
  margin-top: 5px;
  margin-bottom: 0;
}

.integration-atlassian img {
  width: 100%;
}

.integration-cognidesk {
  background-color: #eceff1;
  padding: 20px 30px;
  border-radius: 50%;
  width: 180px;
  height: 180px;
  border: 1px solid #e4e4e4;
  /* box-shadow: 10px 20px 80px 0 #cfd8dc; */
  margin: 50px 0;
}

.integration-cognidesk p {
  font-size: 10px;
  font-family: "Roboto-Regular";
  text-align: center;
  color: #b0bec5;
  text-transform: uppercase;
  margin-top: 5px;
  margin-bottom: 0;
}

.integration-cognidesk img {
  width: 100%;
}

.integration-content-wrap ul {
  padding-left: 0px;
  margin-bottom: 0px;
}

.integration-content-wrap ul li {
  display: table-cell;
  vertical-align: top;
  opacity: 0;
  transform: scale(0.6, 1);
}

.integration-content-wrap ul li:nth-child(even) {
  vertical-align: bottom;
}

.integration-content-wrap ul li:nth-child(1) {
  vertical-align: top;
}

.integration-content-wrap ul li:nth-child(2) {
  vertical-align: bottom;
}

.integration-content-wrap ul li:nth-child(3) {
  vertical-align: top;
}

.integration-content-wrap ul li:nth-child(4) {
  vertical-align: bottom;
}

.integration-content-wrap ul li:nth-child(6) {
  vertical-align: top;
}

.integration-content-wrap ul li:nth-child(7) {
  vertical-align: bottom;
}

.integration-content-wrap ul li:nth-child(8) {
  vertical-align: top;
}

.integration-content-wrap ul li:nth-child(9) {
  vertical-align: bottom;
}

.integration-content-wrap ul li {
  opacity: 1;
  transform: scale(1, 1);
}

/*** 

====================================================================
	Know How Cognidesk
====================================================================

    ***/

.know-how-assistant {
  background-color: #eceff1;
  padding: 50px 0px;
  width: 100%;
  float: left;
}

.know-how-assistant-content h3 {
  font-size: 50px;
  font-family: "Roboto-Bold";
  line-height: 1.2;
  letter-spacing: -0.2px;
  text-align: left;
  color: #333333;
  margin-bottom: 40px;
}

.know-how-assistant-content .start-free-trail {
  text-align: left;
}

.start-free-trail a {
  font-family: "Gordita-Medium";
  font-size: 16px;
  text-align: center;
  color: #ffffff;
  background-color: #2468f6;
  padding: 16px 50px;
  border-radius: 30px !important;
  /* text-transform: uppercase; */
}

.start-free-trail a:hover {
  color: #ffffff;
  text-decoration: none;
}

.know-how-assistant-image img {
  width: 100%;
}

.know-how-assistant-content p {
  font-family: "Roboto-Regular";
  font-size: 18px;
  line-height: 1.54;
  letter-spacing: -0.2px;
  color: #485a63;
  margin-bottom: 40px;
  margin-right: 6em;
}

/*** 

====================================================================
	Future Communication
====================================================================

    ***/

.future-communication {
  padding: 70px 0 100px 0;
}

.future-communication h1 {
  line-height: 1.29;
  letter-spacing: -0.2px;
  text-align: center;
  color: #333333;
  font-family: Montserrat-SemiBold;
  font-size: 64px;
  padding: 0 0px;
  margin-bottom: 40px;
}

.future-communication p {
  font-family: "Roboto-Regular";
  font-size: 20px;
  line-height: 1.67;
  text-align: center;
  color: #485a63;
  margin-bottom: 0;
}

/*** 

====================================================================
	AI Adoption Space
====================================================================

    ***/

.ai-adoption-content {
  background-color: #ffffdb;
  border-radius: 10px;
  padding: 40px;
  margin-bottom: 40px;
}

.ai-adoption-left h3 {
  font-family: Montserrat-SemiBold;
  font-size: 32px;
  line-height: 1.32;
  letter-spacing: -1.6px;
  color: #333333;
  margin-bottom: 40px;
}

.ai-adoption-project-left {
  background-color: #ffffdb;
  border-radius: 10px;
  padding: 40px;
  margin-bottom: 40px;
}

.ai-adoption-project-left h3 {
  font-family: Montserrat-SemiBold;
  font-size: 32px;
  line-height: 1.32;
  letter-spacing: -1.6px;
  color: #333333;
  margin-bottom: 40px;
}

.ai-adoption-project-left p {
  font-family: "Roboto-Regular";
  font-size: 16px;
  line-height: 30px;
  letter-spacing: -0.2px;
  color: #485a63;
  margin-bottom: 40px;
}

.ai-adoption-project-left h3 a {
  color: #333333;
  text-decoration: none;
}

.ai-adoption-project-left img {
  width: 100%;
  margin-bottom: 40px;
}

.ai-adoption-left h3 a {
  color: #333333;
  text-decoration: none;
}

.ai-adoption-left p {
  font-family: "Roboto-Regular";
  font-size: 16px;
  line-height: 30px;
  letter-spacing: -0.2px;
  color: #485a63;
  margin-bottom: 40px;
}

.read-more-button ul li {
  display: inline-block;
}

.read-more-content {
  font-size: 14px;
  font-family: "Roboto-Regular";
  color: #2468f6;
}

.read-more-button ul li a {
  color: #2468f6;
  padding: 0;
  cursor: pointer;
}

.read-more-button ul li a:hover {
  color: #1757dc;
  padding: 0;
  text-decoration: underline;
}

.read-more-button ul {
  padding-left: 0px;
}

.ai-adoption-left {
  padding: 20px 0;
}

.ai-adoption-right {
  text-align: center;
}

.ai-adoption-right img {
  width: 100%;
}

/*** 

====================================================================
	Internet of Everything
====================================================================

    ***/

.internet-of-everything-content-bg {
  height: 100%;
  background: linear-gradient(180deg, #daf8ff 98%, #ffffff 2%);
}

.internet-of-everything-content {
  background-color: #daf8ff;
  border-radius: 10px;
  padding: 40px;
  margin-bottom: 40px;
}

.internet-is-it-everything img {
  width: 100%;
}

.internet-is-it-everything h3 a {
  color: #333333;
  text-decoration: none;
}

.internet-is-it-everything {
  background-color: #daf8ff;
  border-radius: 10px;
  padding: 40px;
  margin-bottom: 40px;
}

.internet-is-it-everything img {
  width: 100%;
  margin-bottom: 40px;
}

.internet-is-it-everything h3 {
  font-family: Montserrat-SemiBold;
  font-size: 32px;
  line-height: 1.32;
  letter-spacing: -1.6px;
  color: #333333;
  margin-bottom: 40px;
}

.internet-is-it-everything p {
  font-family: "Roboto-Regular";
  font-size: 16px;
  line-height: 30px;
  letter-spacing: -0.2px;
  color: #485a63;
  margin-bottom: 40px;
}

.internet-of-everything-left h3 {
  font-family: Montserrat-SemiBold;
  font-size: 32px;
  line-height: 1.32;
  letter-spacing: -1.6px;
  color: #333333;
  margin-bottom: 40px;
}

.internet-of-everything-left p {
  font-family: "Roboto-Regular";
  font-size: 16px;
  line-height: 30px;
  letter-spacing: -0.2px;
  color: #485a63;
  margin-bottom: 40px;
}

.read-more-button ul li {
  display: inline-block;
}

.read-more-content {
  font-size: 14px;
  font-family: "Roboto-Regular";
  color: #2468f6;
}

.read-more-button ul li a {
  color: #2468f6;
  padding: 0;
}

.read-more-button ul li a:hover {
  color: #1757dc;
  padding: 0;
  text-decoration: underline;
}

.read-more-button ul {
  padding-left: 0px;
}

.internet-of-everything-left {
  padding: 20px 0;
}

.internet-of-everything-right {
  text-align: center;
}

.internet-of-everything-right img {
  width: 100%;
}

/*** 

====================================================================
	Killer AI Project
====================================================================

    ***/

.killer-ai-project-left {
  background-color: #ffebed;
  border-radius: 10px;
  padding: 40px;
  margin-bottom: 40px;
}

.killer-ai-project-left h3 {
  font-family: Montserrat-SemiBold;
  font-size: 32px;
  line-height: 1.32;
  letter-spacing: -1.6px;
  color: #333333;
  margin-bottom: 40px;
  text-align: left;
}

.killer-ai-project-left p {
  font-family: "Roboto-Regular";
  font-size: 16px;
  line-height: 30px;
  letter-spacing: -0.2px;
  color: #485a63;
  margin-bottom: 40px;
  text-align: left;
}

.container.killer-ai-project-padding {
  padding: 0px;
}

.read-more-button {
  text-align: left;
}

.read-more-button ul li {
  display: inline-block;
}

.read-more-content {
  font-size: 14px;
  font-family: "Roboto-Regular";
  color: #2468f6;
}

.read-more-button ul li a {
  color: #2468f6;
  padding: 0;
}

.read-more-button ul li a:hover {
  color: #1757dc;
  padding: 0;
  text-decoration: underline;
}

.killer-ai-project-left h3 a {
  color: #333333;
  text-decoration: none;
}

.read-more-button ul {
  padding-left: 0px;
}

.killer-ai-project-left img {
  margin-bottom: 40px;
}

.killer-ai-project-left {
  text-align: center;
}

.killer-ai-project-right {
  background-color: #d9e4ff;
  border-radius: 10px;
  padding: 40px;
  margin-bottom: 40px;
}

.killer-ai-project-right h3 {
  font-family: Montserrat-SemiBold;
  font-size: 32px;
  line-height: 1.32;
  letter-spacing: -1.6px;
  color: #333333;
  margin-bottom: 40px;
  text-align: left;
}

.killer-ai-project-right p {
  font-family: "Roboto-Regular";
  font-size: 16px;
  line-height: 30px;
  letter-spacing: -0.2px;
  color: #485a63;
  margin-bottom: 40px;
  text-align: left;
}

.killer-ai-project-right h3 a {
  color: #333333;
  text-decoration: none;
}

.killer-ai-project-right img {
  margin-bottom: 40px;
}

.killer-ai-project-right {
  text-align: center;
}

.killer-ai-project-left-green h3 a {
  color: #333333;
  text-decoration: none;
}

.killer-ai-project-right-bg {
  height: 100%;
  background: linear-gradient(180deg, #d9e4ff 70%, #ffffff 30%);
}

.killer-ai-project-left-bg {
  height: 100%;
  background: linear-gradient(180deg, #ffebed 70%, #ffffff 30%);
}

.killer-ai-project-left-green-bg {
  height: 100%;
  background: linear-gradient(180deg, #a7ffeb 95%, #ffffff 5%);
}

.killer-ai-project-left-green {
  background-color: #a7ffeb;
  border-radius: 10px;
  padding: 40px;
  margin-bottom: 40px;
}

.killer-ai-project-left-green h3 {
  font-family: Montserrat-SemiBold;
  font-size: 32px;
  line-height: 1.32;
  letter-spacing: -1.6px;
  color: #333333;
  margin-bottom: 40px;
  text-align: left;
}

.killer-ai-project-left-green p {
  font-family: "Roboto-Regular";
  font-size: 16px;
  line-height: 30px;
  letter-spacing: -0.2px;
  color: #485a63;
  margin-bottom: 40px;
  text-align: left;
}

.workplace-support-perish-bg {
  height: 100%;
  background: linear-gradient(180deg, #daf8ff 70%, #ffffff 30%);
}

.workplace-support-perish-left {
  background-color: #daf8ff;
  border-radius: 10px;
  padding: 40px;
  margin-bottom: 40px;
}

.workplace-support-perish-left h3 {
  font-family: Montserrat-SemiBold;
  font-size: 32px;
  line-height: 1.32;
  letter-spacing: -1.6px;
  color: #333333;
  margin-bottom: 40px;
  text-align: left;
}

.workplace-support-perish-left .ai-adoption-right img {
  margin-bottom: 0;
}

.workplace-support-perish-left .ai-adoption-left {
  padding: 0 20px;
}

.assiatant-press-release-left .internet-of-everything-right img {
  margin-bottom: 0;
}

.workplace-support-perish-left h3 a {
  text-decoration: none;
  color: #333333;
}

.workplace-support-perish-left p {
  font-family: "Roboto-Regular";
  font-size: 16px;
  line-height: 30px;
  letter-spacing: -0.2px;
  color: #485a63;
  margin-bottom: 40px;
  text-align: left;
}

.assistant-press-release-bg {
  height: 100%;
  background: linear-gradient(180deg, #fef8e1 70%, #ffffff 30%);
}

.assiatant-press-release-left {
  background-color: #fef8e1;
  border-radius: 10px;
  padding: 40px;
  margin-bottom: 40px;
}

.assiatant-press-release-left h3 {
  font-family: Montserrat-SemiBold;
  font-size: 32px;
  line-height: 1.32;
  letter-spacing: -1.6px;
  color: #333333;
  margin-bottom: 40px;
  text-align: left;
}

.assiatant-press-release-left p {
  font-family: "Roboto-Regular";
  font-size: 16px;
  line-height: 30px;
  letter-spacing: -0.2px;
  color: #485a63;
  margin-bottom: 40px;
  text-align: left;
}

.assiatant-press-release-left img {
  width: 100%;
  margin-bottom: 40px;
}

.assiatant-press-release-left h3 a {
  color: #333333;
  text-decoration: none;
}

.why-assistant-left-bg {
  height: 100%;
  background: linear-gradient(180deg, #d6ffff 70%, #ffffff 30%);
}

.why-assistant-left {
  background-color: #d6ffff;
  border-radius: 10px;
  padding: 40px;
  margin-bottom: 40px;
}

.why-assistant-left h3 {
  font-family: Montserrat-SemiBold;
  font-size: 32px;
  line-height: 1.32;
  letter-spacing: -1.6px;
  color: #333333;
  margin-bottom: 40px;
  text-align: left;
}

.why-assistant-left p {
  font-family: "Roboto-Regular";
  font-size: 16px;
  line-height: 30px;
  letter-spacing: -0.2px;
  color: #485a63;
  margin-bottom: 40px;
  text-align: left;
}

.why-assistant-left h3 a {
  color: #333333;
  text-decoration: none;
}

.why-assistant-left img {
  width: 100%;
  margin-bottom: 40px;
}

.what-is-assistant-left-bg {
  height: 100%;
  background: linear-gradient(180deg, #f5f6fd 70%, #ffffff 30%);
}

.what-is-assistant-left {
  background-color: #f5f6fd;
  border-radius: 10px;
  padding: 40px;
  margin-bottom: 40px;
}

.what-is-assistant-left h3 {
  font-family: Montserrat-SemiBold;
  font-size: 32px;
  line-height: 1.32;
  letter-spacing: -1.6px;
  color: #333333;
  margin-bottom: 40px;
  text-align: left;
}

.what-is-assistant-left p {
  font-family: "Roboto-Regular";
  font-size: 16px;
  line-height: 30px;
  letter-spacing: -0.2px;
  color: #485a63;
  margin-bottom: 40px;
  text-align: left;
}

.what-is-assistant-left h3 a {
  color: #333333;
  text-decoration: none;
}

.what-is-assistant-left img {
  width: 100%;
  margin-bottom: 40px;
}

.intelligent-automation-assistant-left-bg {
  height: 100%;
  background: linear-gradient(180deg, #e2ffea 70%, #ffffff 30%);
}

.intelligent-automation-assistant-left {
  background-color: #e2ffea;
  border-radius: 10px;
  padding: 40px;
  margin-bottom: 40px;
}

.intelligent-automation-assistant-left h3 {
  font-family: Montserrat-SemiBold;
  font-size: 32px;
  line-height: 1.32;
  letter-spacing: -1.6px;
  color: #333333;
  margin-bottom: 40px;
  text-align: left;
}

.intelligent-automation-assistant-left p {
  font-family: "Roboto-Regular";
  font-size: 16px;
  line-height: 30px;
  letter-spacing: -0.2px;
  color: #485a63;
  margin-bottom: 40px;
  text-align: left;
}

.intelligent-automation-assistant-left img {
  width: 100%;
  margin-bottom: 40px;
}

.intelligent-automation-assistant-left h3 a {
  color: #333333;
  text-decoration: none;
}

.workplace-support-perish-left img {
  width: 100%;
  margin-bottom: 40px;
}

.killer-ai-project {
  margin-bottom: 110px;
}

.killer-ai-project-left img {
  width: 100%;
}

.killer-ai-project-right img {
  width: 100%;
}

/*** 

====================================================================
	HDI Blog
====================================================================

***/

.hdi-press-release-bg {
  height: 100%;
  background: linear-gradient(180deg, #e1edfe 70%, #fff 0);
}

.hdi-ai-project-right {
  background: #e1edfe;
  border-radius: 10px;
  padding: 40px;
  margin-bottom: 40px;
}

/*** 

====================================================================
	Space Explorations
====================================================================

    ***/

.space-explorations-content {
  text-align: center;
}

.space-explorations h1 {
  line-height: 1.29;
  letter-spacing: -1.6px;
  text-align: center;
  color: #333333;
  font-family: Montserrat-SemiBold;
  font-size: 64px;
  padding: 0 0px;
  margin-bottom: 40px;
}

.space-explorations h6 {
  font-family: "Roboto-Regular";
  font-size: 20px;
  line-height: 1.67;
  text-align: center;
  color: #485a63;
  margin-bottom: 40px;
}

.space-explorations p {
  font-family: "Roboto-Regular";
  font-size: 18px;
  line-height: 30px;
  text-align: left;
  color: #485a63;
  margin-bottom: 40px;
  padding: 0 7.5em;
}

.read-more-button-center {
  text-align: center;
}

.read-more-button-center ul li {
  display: inline-block;
}

.read-more-button-center ul li a {
  color: #2468f6;
  padding: 0;
}

.read-more-button-center ul li a:hover {
  color: #1757dc;
  padding: 0;
  text-decoration: underline;
}

.read-more-button-center ul {
  padding-left: 0px;
  margin-bottom: 40px;
}

/*** 

====================================================================
	Statistics Boggle
====================================================================

    ***/

.statistics-boggle-content p,
ol {
  font-family: "Roboto-Regular";
  font-size: 16px;
  line-height: 30px;
  text-align: left;
  color: #485a63;
  margin-bottom: 40px;
}

.statistics-boggle-content b {
  color: #333333;
  font-family: "Gordita-Bold";

  font-weight: bolder;
}

.statistics-boggle-content h3 {
  font-family: "Roboto-Bold";
  font-size: 28px;
  letter-spacing: -0.2px;
  color: #333333;
  margin-bottom: 30px;
  padding: 0 15px;
  line-height: 1.67;
}

.alpha_letters {
  width: 100%;
  float: left;
}

.alpha_letters ol {
  margin: 0px 15px;
  margin-bottom: 40px;
}

.alpha_letters .alpha_p {
  margin-bottom: 15px;
}

.numders_list_p .alpha_p {
  margin-bottom: 15px;
}

.alpha_letters ul li {
  list-style: inherit !important;
  margin-bottom: 10px !important;
}

.read-more-content a {
  color: #2468f6;
}

/*** 

====================================================================
	Whooping Percent
====================================================================

    ***/

.whooping-percent-bg {
  background-color: #ffffdb;
  margin-bottom: 40px;
}

.whooping-percent-content h4 {
  font-family: Montserrat-Bold;
  font-size: 36px;
  font-weight: 600;
  line-height: 1.58;
  letter-spacing: -1.6px;
  text-align: center;
  color: #333333;
  padding: 53px 120px;
}

ul.statistics-boggle-list {
  padding-bottom: 40px;
  width: 70%;
  margin: 0 auto;
  color: #485a63;
}

ul.killer-boggle-list {
  padding-bottom: 40px;
  width: 75%;
  margin: 0 auto;
  color: #485a63;
}

ul.killer-boggle-list li {
  margin-bottom: 10px;
  line-height: 30px;
  text-align: left;
}

.john-joe-list {
  padding: 40px 0;
  border-top: 1px solid #cfd8dc;
  border-bottom: 1px solid #cfd8dc;
  margin-bottom: 100px;
}

.row-picture-left {
  display: inline-block;
  padding-right: 16px;
}

.row-picture-left a img {
  width: 100px;
  height: 100px;
}

.row-text-content h5 {
  font-family: "Roboto-Medium";
  font-size: 18px;
  color: #485a63;
  margin-bottom: 12px;
}

.statistics-boggle-content span {
  font-family: "Roboto-Regular";
  font-size: 16px;
  line-height: 1.67;
  text-align: left;
  color: #485a63;
}

.statistics-boggle-content h2 {
  font-family: "Roboto-Bold";
  font-size: 38px;
  letter-spacing: -0.2px;
  color: #333333;
  margin-bottom: 40px;
  padding: 0 2.6em;
}

.row-text-content {
  padding: 10px 0;
}

a.share-text {
  font-family: "Roboto-Regular";
  font-size: 16px;
  color: #485a63;
  text-transform: uppercase;
}

.row-social-content a {
  color: #485a63;
  padding-left: 20px;
}

.row-social-content {
  padding: 40px 0;
  text-align: right;
}

.read-next-content {
  font-family: "Roboto-Regular";
  font-size: 24px;
  text-align: center;
  color: #485a63;
  text-transform: uppercase;
  margin-bottom: 60px;
}

.space-explorations-content img {
  width: 100%;
}

.blog_details_bg {
  height: 100%;
  background: linear-gradient(180deg, #ffffdb 70%, #ffffff 30%);
}

/*** 

====================================================================
	Privacy Policy
====================================================================

    ***/

.privacy-policy {
  margin-bottom: 0px;
}

.privacy-policy .statistics-boggle-content h6 {
  font-size: 18px;
  font-family: "Gordita-Medium";
  margin-bottom: 20px;
  padding: 0px 15px;
}

.privacy-policy .statistics-boggle-content ul li {
  color: #485a63;
  font-family: "Gordita-Regular";
}

.statistics-boggle-content ul,
ol li:last-child {
  margin-bottom: 0px !important;
}

.privacy-policy.statistics-boggle .alpha_p {
  margin-bottom: 15px;
}

/*** 

====================================================================
	Terms & Conditions
====================================================================

    ***/

/*** 

====================================================================
	Home Page Responsive for Landscape and Portrait
====================================================================

    ***/

@media (min-width: 320px) and (max-width: 480px) {
  .header-logo img {
    width: 120px;
    position: relative;
    top: 4px;
  }

  .free-trial a {
    font-size: 14px;
  }

  .free-trial {
    padding: 2px 10px 2px 10px;
  }

  .free-trial:hover {
    padding: 2px 10px 2px 10px;
  }

  .main-download-broucher {
    font-size: 14px;
    padding: 2px 10px;
    margin-top: 10px;
  }

  .header-logo {
    margin: 40px 0px;
  }

  .hamburger svg {
    display: block;
  }

  .hamburger {
    position: absolute;
    right: 30px;
    top: 3px;
    cursor: pointer;
  }

  .main-footer {
    padding: 40px 0;
  }

  .footer-content {
    padding: 20px 0;
    text-align: center;
  }

  .signup-demo {
    padding: 120px 0;
  }

  .signup-slider-button {
    text-align: center;
  }

  .download-broucher {
    padding: 20px 0;
  }

  .try-now-content p {
    padding: 0;
  }

  .try-now-content h2 {
    padding: 0;
  }

  .how-it-pointer {
    display: none;
  }

  .right-works {
    margin-top: 0;
  }

  .left-works {
    margin-top: 0;
  }

  .benefits-workplace {
    background-image: none;
  }

  .footer-content ul {
    padding: 0 25px;
  }

  .footer-content h3 {
    padding: 0 25px;
    margin-bottom: 25px;
  }

  .footer-content img {
    padding: 0 25px;
  }

  .dialog-editor-list ul li {
    margin: 0 15px 15px;
  }

  .dialog-editor-list ul li img {
    width: 125px;
  }

  .how-it-works {
    padding: 25px 0;
  }

  .how-it-works-content h5 {
    font-size: 28px;
    font-family: "Roboto-Bold";
    margin-bottom: 30px;
    cursor: pointer;
  }

  .try-now-content h2 {
    text-align: left;
  }

  .try-now-content p {
    text-align: left;
  }

  .main-slider-left {
    padding: 0 0 30px;
  }

  .benefits-workplace-content h3 {
    font-size: 20px;
    margin-bottom: 10px;
  }

  .benefits-workplace-content p {
    padding: 0;
    margin-bottom: 30px;
  }

  .how-it-works-content h3 {
    padding: 0;
    font-size: 20px;
    text-align: center;
  }

  .how-it-works-content p {
    padding: 0;
    text-align: center;
    margin-bottom: 30px;
  }

  .center-works {
    margin-top: 0px;
  }

  .main-slider-right img {
    padding: 0em 0;
  }

  .dialog-editor-content p {
    margin-bottom: 20px;
    padding: 0 0em;
  }

  .dialog-editor .carousel-indicators li {
    margin-bottom: 0px;
  }

  .dialog-editor .carousel-indicators {
    margin: 0;
  }

  .our-technology-partners-content h5 {
    margin-bottom: 20px;
    margin-top: 30px;
  }

  .benefits-workplace {
    margin-top: 0px;
  }

  .benefits-works-content h3 {
    font-size: 28px;
    margin-bottom: 30px;
  }

  .benefits-works-content p {
    padding: 0 1em;
    margin-bottom: 30px;
  }

  .benefits-works-content h5 {
    font-size: 16px;
    font-weight: 500;
    text-align: center;
    color: #adbec6;
    font-family: "Roboto-Regular";
    margin-bottom: 40px;
    text-transform: uppercase;
    letter-spacing: 3px;
  }

  .benefits-works-content {
    text-align: center;
  }

  .dialog-editor-list {
    margin-bottom: 30px;
  }

  .benefits-workplace-content {
    text-align: center;
  }

  .main-slider-right img {
    width: 80%;
  }

  .main-slider-right {
    text-align: center;
   
  }

  .how-it-works-bg {
    background-image: none;
    padding: 25px 0 0;
  }

  .how-it-works-content img {
    margin-bottom: 20px;
    width: 100px;
    height: 100px;
  }

  .how-it-works-content {
    text-align: center;
  }

  .interations-content h3 {
    font-size: 28px;
    font-family: "Roboto-Bold";
    margin-bottom: 30px;
  }

  .interations-content p {
    padding: 0 0em;
  }

  .integration-project-wrap {
    padding: 30px 0 30px 0;
  }

  .integration-content-wrap ul li {
    width: 33.3333333333%;
    float: left;
  }

  .integration-jira {
    width: 80px;
    height: 80px;
    margin: 0 auto;
    margin-bottom: 0px;
    padding: 15px;
  }

  .integration-content-wrap ul li:nth-child(1) {
    margin-top: 40px;
  }

  .integration-content-wrap ul li:nth-child(3) {
    margin-top: 40px;
  }

  .integration-content-wrap ul li:nth-child(4) {
    margin: 20px 0;
  }

  .integration-content-wrap ul li:nth-child(4) {
    margin: 20px 0;
  }

  .integration-content-wrap ul li:nth-child(6) {
    margin: 20px 0;
  }

  .integration-content-wrap ul li:nth-child(8) {
    margin: 40px auto;
  }

  .integration-atlassian.mob-jira-6 {
    margin-right: 0;
  }

  .integration-atlassian.mob-twilo-4 {
    margin-left: 0;
  }

  .integration-jira.mob-active-1 {
    margin-right: 0;
  }

  .integration-slack.mob-slack-3 {
    margin-left: 0;
  }

  .integration-slack.mob-slack-7 {
    margin-right: 0;
  }

  .integration-jira.mob-jira-9 {
    margin-left: 0;
  }

  .integration-jira img {
    width: 30px;
    height: 30px;
  }

  .integration-jira p {
    font-size: 6px;
  }

  .integration-amazon {
    width: 80px;
    height: 80px;
    margin: 0 auto;
    margin-bottom: 0px;
    padding: 10px 15px;
  }

  .integration-amazon img {
    width: 40px;
    height: 40px;
  }

  .integration-amazon p {
    font-size: 6px;
    margin-top: 0;
  }

  .integration-slack {
    width: 80px;
    height: 80px;
    margin: 0 auto;
    margin-bottom: 0px;
    padding: 15px;
  }

  .integration-slack img {
    width: 30px;
    height: 30px;
  }

  .integration-slack p {
    font-size: 6px;
  }

  .integration-atlassian {
    width: 80px;
    height: 80px;
    margin: 0 auto;
    margin-bottom: 0px;
    padding: 15px;
  }

  .integration-atlassian img {
    width: 30px;
    height: 30px;
  }

  .integration-atlassian p {
    font-size: 6px;
  }

  .integration-cognidesk {
    width: 120px;
    height: 120px;
    margin: 0px auto;
    padding: 10px 25px;
  }

  .know-how-assistant-content h3 {
    font-size: 32px;
    font-family: "Roboto-Bold";
    text-align: center;
    margin-bottom: 30px;
    line-height: 1.5;
  }

  .know-how-assistant {
    background-color: #eceff1;
    padding: 50px 0;
    margin-top: 30px;
  }

  .know-how-assistant-content p {
    font-size: 16px;
    line-height: 30px;
    margin-bottom: 30px;
    margin-right: 0;
    text-align: center;
  }

  .start-free-trail a {
    font-size: 14px;
    padding: 12px 30px;
  }

  .know-how-assistant-content .start-free-trail {
    text-align: center;
  }

  .know-how-assistant-image img {
    width: 80%;
  }

  .know-how-assistant-image {
    text-align: center;
    margin-top: 60px;
  }

  .footer-content a img {
    width: 220px;
    padding-left: 25px;
    margin-bottom: 0px !important;
  }

  .main-footer .social {
    margin-left: 20px;
  }

  .text-footer {
    padding: 30px 0 30px 0;
  }

  .main-slider-button ul {
    padding-left: 0;
    text-align: left;
  }

  .bg_img_assistant_top {
    height: 360px !important;
  }

  .bg_img_assistant_top {
    height: 360px !important;
  }

  .--footer-border .--quick-link:last-child h4:after {
    display: none;
  }
}

@media (min-width: 481px) and (max-width: 767px) {
  .header-logo img {
    width: 120px;
    position: relative;
    top: 4px;
  }



 

  .free-trial a {
    font-size: 14px;
  }

  .free-trial {
    padding: 2px 10px 2px 10px;
  }

  .free-trial:hover {
    padding: 2px 10px 2px 10px;
  }

  .main-download-broucher {
    font-size: 14px;
    padding: 2px 10px;
    margin-top: 10px;
  }

  .header-logo {
    margin: 40px 0px;
  }

  .hamburger svg {
    display: block;
  }

  .hamburger {
    position: absolute;
    right: 30px;
    top: 3px;
    cursor: pointer;
  }

  .main-footer {
    padding: 40px 0;
  }

  .footer-content {
    padding: 20px 0;
    text-align: center;
  }

  .signup-demo {
    padding: 120px 0;
  }

  .signup-slider-button {
    text-align: center;
  }

  .download-broucher {
    padding: 20px 0;
  }

  .try-now-content p {
    padding: 0;
  }

  .try-now-content h2 {
    padding: 0;
  }

  .how-it-pointer {
    display: none;
  }

  .right-works {
    margin-top: 0;
  }

  .left-works {
    margin-top: 0;
  }

  .benefits-workplace {
    background-image: none;
  }

  .footer-content ul {
    padding: 0 25px;
  }

  .footer-content h3 {
    padding: 0 25px;
    margin-bottom: 25px;
  }

  .footer-content img {
    padding: 0 25px;
  }

  .dialog-editor-list ul li {
    margin: 0 20px 20px;
  }

  .dialog-editor-list ul li img {
    width: 130px;
  }

  .how-it-works {
    padding: 25px 0;
  }

  .how-it-works-content h5 {
    font-size: 28px;
    font-family: "Roboto-Bold";
    margin-bottom: 30px;
  }

  .try-now-content h2 {
    text-align: left;
  }

  .try-now-content p {
    text-align: left;
  }

  .main-slider-left {
    padding: 0 0 30px;
  }

  .benefits-workplace-content h3 {
    font-size: 20px;
    margin-bottom: 10px;
  }

  .benefits-workplace-content p {
    padding: 0;
    margin-bottom: 30px;
  }

  .how-it-works-content h3 {
    padding: 0;
    font-size: 20px;
    text-align: center;
  }

  .how-it-works-content p {
    padding: 0;
    text-align: center;
    margin-bottom: 30px;
  }

  .center-works {
    margin-top: 0px;
  }

  .main-slider-right img {
    padding: 0em 0;
  }

  .dialog-editor-content p {
    margin-bottom: 20px;
    padding: 0 0em;
  }

  .dialog-editor .carousel-indicators li {
    margin-bottom: 0px;
  }

  .dialog-editor .carousel-indicators {
    margin: 0;
  }

  .our-technology-partners-content h5 {
    margin-bottom: 20px;
    margin-top: 30px;
  }

  .benefits-workplace {
    margin-top: 0px;
  }

  .benefits-works-content h3 {
    font-size: 28px;
    margin-bottom: 30px;
  }

  .benefits-works-content p {
    padding: 0 1em;
    margin-bottom: 30px;
  }

  .dialog-editor-list {
    margin-bottom: 30px;
  }

  .benefits-workplace-content {
    text-align: center;
  }

  .main-slider-right img {
    width: 100%;
  }

  .main-slider-right {
    text-align: center;
    display: none;
  }

  .how-it-works-bg {
    background-image: none;
    padding: 25px 0 0;
  }

  .how-it-works-content img {
    margin-bottom: 20px;
    width: 100px;
    height: 100px;
  }

  .how-it-works-content {
    text-align: center;
  }

  .interations-content h3 {
    font-size: 28px;
    font-family: "Roboto-Bold";
    margin-bottom: 30px;
  }

  .interations-content p {
    padding: 0 0em;
  }

  .integration-project-wrap {
    padding: 30px 0 30px 0;
  }

  .integration-content-wrap ul li {
    width: 33.3333333333%;
    float: left;
  }

  .integration-jira {
    width: 80px;
    height: 80px;
    margin: 0 auto;
    margin-bottom: 0px;
    padding: 15px;
  }

  .integration-content-wrap ul li:nth-child(1) {
    margin-top: 50px;
  }

  .integration-content-wrap ul li:nth-child(3) {
    margin-top: 50px;
  }

  .integration-content-wrap ul li:nth-child(4) {
    margin: 30px 0;
  }

  .integration-content-wrap ul li:nth-child(4) {
    margin: 30px 0;
  }

  .integration-content-wrap ul li:nth-child(6) {
    margin: 30px 0;
  }

  .integration-content-wrap ul li:nth-child(8) {
    margin: 50px auto;
  }

  .integration-atlassian.mob-jira-6 {
    margin-right: 0;
  }

  .integration-atlassian.mob-twilo-4 {
    margin-left: 0;
  }

  .integration-jira.mob-active-1 {
    margin-right: 25px;
  }

  .integration-slack.mob-slack-3 {
    margin-left: 25px;
  }

  .integration-slack.mob-slack-7 {
    margin-right: 25px;
  }

  .integration-jira.mob-jira-9 {
    margin-left: 25px;
  }

  .integration-jira img {
    width: 30px;
    height: 30px;
  }

  .integration-jira p {
    font-size: 6px;
  }

  .integration-amazon {
    width: 80px;
    height: 80px;
    margin: 0 auto;
    margin-bottom: 0px;
    padding: 10px 15px;
  }

  .integration-amazon img {
    width: 40px;
    height: 40px;
  }

  .integration-amazon p {
    font-size: 6px;
    margin-top: 0;
  }

  .integration-slack {
    width: 80px;
    height: 80px;
    margin: 0 auto;
    margin-bottom: 0px;
    padding: 15px;
  }

  .integration-slack img {
    width: 30px;
    height: 30px;
  }

  .integration-slack p {
    font-size: 6px;
  }

  .integration-atlassian {
    width: 80px;
    height: 80px;
    margin: 0 auto;
    margin-bottom: 0px;
    padding: 15px;
  }

  .integration-atlassian img {
    width: 30px;
    height: 30px;
  }

  .integration-atlassian p {
    font-size: 6px;
  }

  .integration-cognidesk {
    width: 130px;
    height: 130px;
    margin: 0px auto;
    padding: 10px 25px;
  }

  .know-how-assistant-content h3 {
    font-size: 34px;
    font-family: "Roboto-Bold";
    text-align: center;
    margin-bottom: 30px;
    line-height: 1.5;
  }

  .know-how-assistant {
    background-color: #eceff1;
    padding: 50px 0;
    margin-top: 30px;
  }

  .know-how-assistant-content p {
    font-size: 16px;
    line-height: 30px;
    margin-bottom: 30px;
    margin-right: 0;
    text-align: center;
  }

  .start-free-trail a {
    font-size: 14px;
    padding: 12px 30px;
  }

  .know-how-assistant-content .start-free-trail {
    text-align: center;
  }

  .know-how-assistant-image img {
    width: 70%;
  }

  .know-how-assistant-image {
    text-align: center;
    margin-top: 60px;
  }

  .footer-content a img {
    width: 220px;
    padding-left: 25px;
    margin-bottom: 0px !important;
  }

  .main-footer .social {
    margin-left: 20px;
  }

  .text-footer {
    padding: 30px 0 30px 0;
  }

  .main-slider-button ul {
    padding-left: 0;
    text-align: left;
  }

  .bg_img_assistant_top {
    height: 360px !important;
  }

  .--footer-border .--quick-link:last-child h4:after {
    display: none;
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .header-logo img {
    width: 140px;
    position: relative;
    top: 4px;
  }

  .main-slider-left h1 {
    font-size: 32px;
    margin-bottom: 10px;
    line-height: 1.5;
    text-align: left;
  }

  .main-slider-left p {
    font-size: 18px;
    margin-bottom: 25px;
    text-align: left;
    line-height: 30px;
    padding: 0px;
  }

  .free-trial a {
    font-size: 14px;
  }

  .free-trial {
    padding: 2px 10px 2px 10px;
  }

  .free-trial:hover {
    padding: 2px 10px 2px 10px;
  }

  .main-download-broucher {
    font-size: 14px;
    padding: 2px 10px;
    margin-top: 10px;
  }

  .header-logo {
    margin: 40px 0px;
  }

  .hamburger svg {
    display: block;
  }

  .hamburger {
    position: absolute;
    right: 30px;
    top: 3px;
    cursor: pointer;
  }

  .main-footer {
    padding: 40px 0;
  }

  .footer-content {
    padding: 20px 0;
  }

  .signup-demo {
    padding: 120px 0;
  }

  .signup-slider-button {
    text-align: center;
  }

  .download-broucher {
    padding: 20px 0;
  }

  .try-now-content p {
    padding: 0;
  }

  .try-now-content h2 {
    padding: 0;
  }

  .how-it-pointer {
    display: none;
  }

  .right-works {
    margin-top: 0;
  }

  .left-works {
    margin-top: 0;
  }

  .benefits-workplace {
    background-image: none;
  }

  .footer-content ul {
    padding: 0 25px;
  }

  .footer-content h3 {
    padding: 0 25px;
    margin-bottom: 25px;
  }

  .footer-content img {
    padding: 0 25px;
  }

  .dialog-editor-list ul li {
    margin: 0 20px 20px;
  }

  .dialog-editor-list ul li img {
    width: 130px;
  }

  .how-it-works {
    padding: 25px 0;
  }

  .how-it-works-content h5 {
    font-size: 28px;
    font-family: "Roboto-Bold";
    margin-bottom: 30px;
  }

  .try-now-content h2 {
    text-align: left;
  }

  .try-now-content p {
    text-align: left;
  }

  .main-slider-left {
    padding: 0 0 30px;
  }

  .benefits-workplace-content h3 {
    font-size: 20px;
    margin-bottom: 10px;
  }

  .benefits-workplace-content p {
    padding: 0;
    margin-bottom: 30px;
  }

  .how-it-works-content h3 {
    padding: 0;
    font-size: 20px;
    text-align: center;
  }

  .how-it-works-content p {
    padding: 0;
    text-align: center;
    margin-bottom: 30px;
  }

  .center-works {
    margin-top: 0px;
  }

  .main-slider-right img {
    padding: 0em 0;
  }

  .dialog-editor-content p {
    margin-bottom: 20px;
    padding: 0 0em;
  }

  .dialog-editor .carousel-indicators li {
    margin-bottom: 0px;
  }

  .dialog-editor .carousel-indicators {
    margin: 0;
  }

  .our-technology-partners-content h5 {
    margin-bottom: 20px;
    margin-top: 30px;
  }

  .benefits-workplace {
    margin-top: 0px;
  }

  .benefits-works-content h3 {
    font-size: 28px;
    margin-bottom: 30px;
  }

  .benefits-works-content p {
    padding: 0 1em;
    margin-bottom: 30px;
  }

  .dialog-editor-list {
    margin-bottom: 30px;
  }

  .benefits-workplace-content {
    text-align: center;
  }

  .main-slider-right img {
    width: 70%;
  }

  .main-slider-right {
    text-align: center;
  }

  .how-it-works-bg {
    background-image: none;
    padding: 25px 0 0;
  }

  .how-it-works-content img {
    margin-bottom: 20px;
    width: 100px;
    height: 100px;
  }

  .how-it-works-content {
    text-align: center;
  }

  .interations-content h3 {
    font-size: 28px;
    font-family: "Roboto-Bold";
    margin-bottom: 30px;
  }

  .interations-content p {
    padding: 0 0em;
  }

  .integration-project-wrap {
    padding: 30px 0 30px 0;
  }

  .integration-content-wrap ul li {
    width: 33.3333333333%;
    float: left;
  }

  .integration-jira {
    width: 100px;
    height: 100px;
    margin: 0 auto;
    margin-bottom: 0px;
    padding: 20px 15px;
  }

  .integration-content-wrap ul li:nth-child(1) {
    margin-top: 60px;
  }

  .integration-content-wrap ul li:nth-child(3) {
    margin-top: 60px;
  }

  .integration-content-wrap ul li:nth-child(4) {
    margin: 20px 0;
  }

  .integration-content-wrap ul li:nth-child(4) {
    margin: 30px 0;
  }

  .integration-content-wrap ul li:nth-child(6) {
    margin: 30px 0;
  }

  .integration-content-wrap ul li:nth-child(8) {
    margin: 60px auto;
  }

  .integration-atlassian.mob-jira-6 {
    margin-right: 50px;
  }

  .integration-atlassian.mob-twilo-4 {
    margin-left: 50px;
  }

  .integration-jira.mob-active-1 {
    margin-right: 0;
  }

  .integration-slack.mob-slack-3 {
    margin-left: 0;
  }

  .integration-slack.mob-slack-7 {
    margin-right: 0;
  }

  .integration-jira.mob-jira-9 {
    margin-left: 0;
  }

  .integration-jira img {
    width: 40px;
    height: 40px;
  }

  .integration-amazon {
    width: 100px;
    height: 100px;
    margin: 0 auto;
    margin-bottom: 0px;
    padding: 10px 15px;
  }

  .integration-amazon img {
    width: 50px;
    height: 50px;
  }

  .integration-amazon p {
    margin-top: 0;
  }

  .integration-slack {
    width: 100px;
    height: 100px;
    margin: 0 auto;
    margin-bottom: 0px;
    padding: 20px 15px;
  }

  .integration-slack img {
    width: 40px;
    height: 40px;
  }

  .integration-atlassian {
    width: 100px;
    height: 100px;
    margin: 0 auto;
    margin-bottom: 0px;
    padding: 20px 15px;
  }

  .integration-atlassian img {
    width: 40px;
    height: 40px;
  }

  .integration-cognidesk {
    width: 150px;
    height: 150px;
    margin: 0px auto;
    padding: 10px 25px;
  }

  .know-how-assistant-content h3 {
    font-size: 34px;
    font-family: "Roboto-Bold";
    text-align: center;
    margin-bottom: 30px;
    line-height: 1.5;
  }

  .know-how-assistant {
    background-color: #eceff1;
    padding: 50px 0;
    margin-top: 30px;
  }

  .know-how-assistant-content p {
    font-size: 16px;
    line-height: 30px;
    margin-bottom: 30px;
    margin-right: 0;
    text-align: center;
  }

  .start-free-trail a {
    font-size: 14px;
    padding: 12px 30px;
  }

  .know-how-assistant-content .start-free-trail {
    text-align: center;
  }

  .know-how-assistant-image img {
    width: 60%;
  }

  .know-how-assistant .start-free-trail {
    margin-bottom: 40px;
  }

  .know-how-assistant-image {
    text-align: center;
  }

  .footer-content a img {
    width: 220px;
    padding-left: 25px;
    margin-bottom: 30px;
  }

  .main-footer .social {
    margin-left: 20px;
  }

  .text-footer {
    padding: 30px 0 30px 0;
  }

  .main-slider-button ul {
    padding-left: 0;
    text-align: left;
  }

  .bg_img_assistant_top {
    height: 360px !important;
  }
}

/*** 

====================================================================
	Features Page Responsive for Landscape and Portrait
====================================================================

    ***/

@media (min-width: 320px) and (max-width: 480px) {
  .design-transform h1 {
    line-height: 1.5;
    font-size: 32px;
    padding: 0;
    margin-bottom: 30px;
  }

  .design-transform p {
    font-size: 17px;
    line-height: 1.67;
  }

  .design-transform {
    padding: 40px 0 40px 0;
  }

  .explore-best-fetures h5 {
    font-size: 14px;
    margin-bottom: 30px;
    cursor: pointer;
  }

  .features_tab_inner {
    display: inline-block;
    margin-bottom: 40px;
  }

  .features_tab_inner .features_tab_left {
    width: 100%;
    float: left;
  }

  .features_tab_inner .features_tab_right {
    width: 100%;
    float: left;
    padding: 0 15px;
  }

  .features_tab_inner .features_tab_left .features_tab {
    margin-right: 50px;
    margin-left: 0em;
    padding: 0em 0;
  }

  .features_tab_inner .features_tab_left .features_tab li {
    margin: 0px 0px 10px;
    padding: 10px 25px 10px 25px;
  }

  img.image-responsive {
    display: none;
  }

  .capabilities-workplace h5 {
    font-size: 14px;
    margin-bottom: 30px;
    cursor: pointer;
  }

  .capabilities-content {
    text-align: center;
  }

  .capabilities-content h3 {
    font-size: 20px;
    margin-bottom: 10px;
    text-align: center;
  }

  .capabilities-content p {
    font-size: 16px;
    margin-bottom: 40px;
    text-align: center;
  }

  .interations-separator h5 {
    font-size: 14px;
    margin-bottom: 30px;
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .design-transform h1 {
    line-height: 1.5;
    font-size: 32px;
    padding: 0;
    margin-bottom: 30px;
  }

  .design-transform p {
    font-size: 17px;
    line-height: 1.67;
  }

  .design-transform {
    padding: 40px 0 40px 0;
  }

  .explore-best-fetures h5 {
    font-size: 14px;
    margin-bottom: 30px;
  }

  .features_tab_inner {
    display: inline-block;
    margin-bottom: 40px;
  }

  .features_tab_inner .features_tab_left {
    width: 100%;
    float: left;
  }

  .features_tab_inner .features_tab_right {
    width: 100%;
    float: left;
    padding: 0 15px;
  }

  .features_tab_inner .features_tab_left .features_tab {
    margin-right: 50px;
    margin-left: 0em;
    padding: 0em 0;
  }

  .features_tab_inner .features_tab_left .features_tab li {
    margin: 0px 0px 10px;
    padding: 10px 25px 10px 25px;
  }

  img.image-responsive {
    display: none;
  }

  .capabilities-workplace h5 {
    font-size: 14px;
    margin-bottom: 30px;
  }

  .capabilities-content {
    text-align: center;
  }

  .capabilities-content h3 {
    font-size: 20px;
    margin-bottom: 10px;
    text-align: center;
  }

  .capabilities-content p {
    font-size: 16px;
    margin-bottom: 40px;
    text-align: center;
  }

  .interations-separator h5 {
    font-size: 14px;
    margin-bottom: 30px;
  }

  .--footer-border .--quick-link:last-child h4::after {
    display: none;
  }
}

@media (min-width: 481px) and (max-width: 767px) {
  .design-transform h1 {
    line-height: 1.5;
    font-size: 32px;
    padding: 0;
    margin-bottom: 30px;
  }

  .design-transform p {
    font-size: 17px;
    line-height: 1.67;
  }

  .design-transform {
    padding: 40px 0 40px 0;
  }

  .explore-best-fetures h5 {
    font-size: 14px;
    margin-bottom: 30px;
  }

  .features_tab_inner {
    display: inline-block;
    margin-bottom: 40px;
  }

  .features_tab_inner .features_tab_left {
    width: 100%;
    float: left;
  }

  .features_tab_inner .features_tab_right {
    width: 100%;
    float: left;
    padding: 0 15px;
  }

  .features_tab_inner .features_tab_left .features_tab {
    margin-right: 50px;
    margin-left: 0em;
    padding: 0em 0;
  }

  .features_tab_inner .features_tab_left .features_tab li {
    margin: 0px 0px 10px;
    padding: 10px 25px 10px 25px;
  }

  img.image-responsive {
    display: none;
  }

  .capabilities-workplace h5 {
    font-size: 14px;
    margin-bottom: 30px;
  }

  .capabilities-content {
    text-align: center;
  }

  .capabilities-content h3 {
    font-size: 20px;
    margin-bottom: 10px;
    text-align: center;
  }

  .capabilities-content p {
    font-size: 16px;
    margin-bottom: 40px;
    text-align: center;
  }

  .interations-separator h5 {
    font-size: 14px;
    margin-bottom: 30px;
  }

  .--footer-border .--quick-link:last-child h4::after {
    display: none;
  }
}

/*** 

====================================================================
	Blogs Page Responsive for Landscape and Portrait
====================================================================

    ***/

@media (min-width: 320px) and (max-width: 480px) {
  .future-communication h1 {
    line-height: 1.5;
    font-size: 32px;
    padding: 0;
    margin-bottom: 30px;
  }

  .future-communication p {
    font-size: 17px;
    line-height: 1.67;
  }

  .future-communication {
    padding: 40px 0 40px 0;
  }

  .ai-adoption-content h3 {
    font-size: 24px;
    margin-bottom: 30px;
    text-align: center;
    line-height: 1.5;
  }

  .ai-adoption-content p {
    font-size: 16px;
    margin-bottom: 30px;
    text-align: justify;
  }

  .ai-adoption-content h5 {
    font-size: 14px;
    margin-bottom: 30px;
  }

  .read-more-button {
    text-align: left;
    margin-bottom: 0px;
  }

  .ai-adoption-in-space {
    padding: 15px;
  }

  .col-lg-5.col-md-5.col-sm-12.col-xs-12.ai-adoption-left {
    padding: 0;
  }

  .col-lg-7.col-md-7.col-sm-12.col-xs-12.ai-adoption-right {
    padding: 0;
  }

  .internet-of-everything {
    padding: 15px;
  }

  .col-lg-5.col-md-5.col-sm-12.col-xs-12.internet-of-everything-left {
    padding: 0;
  }

  .col-lg-7.col-md-7.col-sm-12.col-xs-12.internet-of-everything-right {
    padding: 0;
  }

  .internet-of-everything-content h3 {
    font-size: 24px;
    margin-bottom: 30px;
    text-align: center;
    line-height: 1.5;
  }

  .internet-of-everything-left p {
    margin-bottom: 30px;
    text-align: justify;
  }

  .internet-of-everything-right img {
    width: 80%;
  }

  .killer-ai-project {
    padding: 15px;
  }

  .ai-adoption-content {
    margin-bottom: 0px;
    padding: 40px 30px;
  }

  .internet-of-everything-content {
    margin-bottom: 0px;
    padding: 40px 30px;
  }

  .killer-ai-project-left h3 {
    font-size: 24px;
    margin-bottom: 30px;
    text-align: center;
    line-height: 1.5;
  }

  .killer-ai-project-left {
    padding: 40px 30px;
    margin-bottom: 40px;
  }

  .killer-ai-project-left p {
    margin-bottom: 30px;
    text-align: justify;
  }

  .killer-ai-project-right h3 {
    font-size: 24px;
    margin-bottom: 30px;
    text-align: center;
    line-height: 1.5;
  }

  .killer-ai-project-right {
    margin-bottom: 40px;
    padding: 40px 30px;
  }

  .killer-ai-project-left img {
    margin-bottom: 30px;
  }

  .killer-ai-project-right img {
    margin-bottom: 30px;
  }

  .killer-ai-project-right p {
    margin-bottom: 30px;
    text-align: justify;
  }

  .ai-adoption-right img {
    margin-top: 15px;
  }

  .killer-ai-project-left-green {
    padding: 40px 30px;
    margin-bottom: 40px;
  }

  .killer-ai-project-left-green h3 {
    font-size: 24px;
    line-height: 1.5;
    margin-bottom: 30px;
    text-align: center;
  }

  .killer-ai-project-left-green p {
    margin-bottom: 30px;
    text-align: justify;
  }

  .killer-ai-project {
    margin-bottom: 30px;
  }

  .workplace-support-perish-left {
    margin-bottom: 10px;
  }

  .assiatant-press-release-left .internet-of-everything-right img {
    width: 100%;
    margin-top: 15px;
  }

  .assiatant-press-release-left {
    margin-bottom: 10px;
  }

  .killer-ai-project-padding .workplace-support-perish-left {
    margin-bottom: 40px;
  }

  .internet-is-it-everything {
    padding: 40px 30px;
    margin-bottom: 40px;
  }

  .ai-adoption-project-left {
    padding: 40px 30px;
    margin-bottom: 40px;
  }

  .intelligent-automation-assistant-left {
    padding: 40px 30px;
    margin-bottom: 40px;
  }

  .what-is-assistant-left {
    padding: 40px 30px;
    margin-bottom: 40px;
  }

  .why-assistant-left {
    padding: 40px 30px;
    margin-bottom: 40px;
  }

  .col-lg-5.col-md-12.col-sm-12.col-xs-12.internet-of-everything-left {
    padding: 0;
  }

  .col-lg-7.col-md-12.col-sm-12.col-xs-12.internet-of-everything-right {
    padding: 0;
  }

  .assiatant-press-release-left {
    padding: 40px 30px;
    margin-bottom: 40px;
  }

  .workplace-support-perish-left {
    padding: 40px 30px;
    margin-bottom: 40px;
  }

  .col-lg-5.col-md-12.col-sm-12.col-xs-12.ai-adoption-left {
    padding: 0;
  }

  .col-lg-7.col-md-12.col-sm-12.col-xs-12.ai-adoption-right {
    padding: 0;
  }

  .--footer-border .--quick-link:last-child h4::after {
    display: none;
  }
}

@media (min-width: 481px) and (max-width: 767px) {
  .future-communication h1 {
    line-height: 1.5;
    font-size: 32px;
    padding: 0;
    margin-bottom: 30px;
  }

  .future-communication p {
    font-size: 17px;
    line-height: 1.67;
  }

  .future-communication {
    padding: 40px 0 40px 0;
  }

  .ai-adoption-content h3 {
    font-size: 28px;
    margin-bottom: 30px;
    text-align: center;
    line-height: 1.5;
  }

  .ai-adoption-content p {
    font-size: 16px;
    margin-bottom: 30px;
    text-align: justify;
  }

  .ai-adoption-content h5 {
    font-size: 14px;
    margin-bottom: 30px;
  }

  .read-more-button {
    text-align: left;
    margin-bottom: 0px;
  }

  .ai-adoption-in-space {
    padding: 15px;
  }

  .col-lg-5.col-md-5.col-sm-12.col-xs-12.ai-adoption-left {
    padding: 0;
  }

  .col-lg-7.col-md-7.col-sm-12.col-xs-12.ai-adoption-right {
    padding: 0;
  }

  .internet-of-everything {
    padding: 15px;
  }

  .col-lg-5.col-md-5.col-sm-12.col-xs-12.internet-of-everything-left {
    padding: 0;
  }

  .col-lg-7.col-md-7.col-sm-12.col-xs-12.internet-of-everything-right {
    padding: 0;
  }

  .internet-of-everything-content h3 {
    font-size: 28px;
    margin-bottom: 30px;
    text-align: center;
    line-height: 1.5;
  }

  .internet-of-everything-left p {
    margin-bottom: 30px;
    text-align: justify;
  }

  .internet-of-everything-right img {
    width: 70%;
  }

  .killer-ai-project {
    padding: 15px;
  }

  .ai-adoption-content {
    margin-bottom: 0px;
    padding: 40px 30px;
  }

  .internet-of-everything-content {
    margin-bottom: 0px;
    padding: 40px 30px;
  }

  .killer-ai-project-left h3 {
    font-size: 28px;
    margin-bottom: 30px;
    text-align: center;
    line-height: 1.5;
  }

  .killer-ai-project-left {
    padding: 40px 30px;
    margin-bottom: 40px;
  }

  .killer-ai-project-left p {
    margin-bottom: 30px;
    text-align: justify;
  }

  .killer-ai-project-right h3 {
    font-size: 28px;
    margin-bottom: 30px;
    text-align: center;
    line-height: 1.5;
  }

  .killer-ai-project-right {
    margin-bottom: 40px;
    padding: 40px 30px;
  }

  .killer-ai-project-left img {
    margin-bottom: 30px;
  }

  .killer-ai-project-right img {
    margin-bottom: 30px;
  }

  .killer-ai-project-right p {
    margin-bottom: 30px;
    text-align: justify;
  }

  .ai-adoption-right img {
    margin-top: 15px;
  }

  .killer-ai-project-left-green {
    padding: 40px 30px;
    margin-bottom: 40px;
  }

  .killer-ai-project-left-green h3 {
    font-size: 28px;
    line-height: 1.5;
    margin-bottom: 30px;
    text-align: center;
  }

  .killer-ai-project-left-green p {
    margin-bottom: 30px;
    text-align: justify;
  }

  .killer-ai-project {
    margin-bottom: 30px;
  }

  .workplace-support-perish-left {
    margin-bottom: 10px;
  }

  .assiatant-press-release-left .internet-of-everything-right img {
    width: 100%;
    margin-top: 15px;
  }

  .assiatant-press-release-left {
    margin-bottom: 10px;
  }

  .killer-ai-project-padding .workplace-support-perish-left {
    margin-bottom: 40px;
  }

  .internet-is-it-everything {
    padding: 40px 30px;
    margin-bottom: 40px;
  }

  .ai-adoption-project-left {
    padding: 40px 30px;
    margin-bottom: 40px;
  }

  .intelligent-automation-assistant-left {
    padding: 40px 30px;
    margin-bottom: 40px;
  }

  .what-is-assistant-left {
    padding: 40px 30px;
    margin-bottom: 40px;
  }

  .why-assistant-left {
    padding: 40px 30px;
    margin-bottom: 40px;
  }

  .col-lg-5.col-md-12.col-sm-12.col-xs-12.internet-of-everything-left {
    padding: 0;
  }

  .col-lg-7.col-md-12.col-sm-12.col-xs-12.internet-of-everything-right {
    padding: 0;
  }

  .assiatant-press-release-left {
    padding: 40px 30px;
    margin-bottom: 40px;
  }

  .workplace-support-perish-left {
    padding: 40px 30px;
    margin-bottom: 40px;
  }

  .col-lg-5.col-md-12.col-sm-12.col-xs-12.ai-adoption-left {
    padding: 0;
  }

  .col-lg-7.col-md-12.col-sm-12.col-xs-12.ai-adoption-right {
    padding: 0;
  }

  .--footer-border .--quick-link:last-child h4::after {
    display: none;
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .future-communication h1 {
    line-height: 1.5;
    font-size: 32px;
    padding: 0;
    margin-bottom: 30px;
  }

  .future-communication p {
    font-size: 17px;
    line-height: 1.67;
  }

  .future-communication {
    padding: 40px 0 40px 0;
  }

  .ai-adoption-content h3 {
    font-size: 28px;
    margin-bottom: 30px;
    text-align: center;
    line-height: 1.5;
  }

  .ai-adoption-content p {
    font-size: 16px;
    margin-bottom: 30px;
    text-align: justify;
  }

  .ai-adoption-content h5 {
    font-size: 14px;
    margin-bottom: 30px;
  }

  .read-more-button {
    text-align: left;
    margin-bottom: 0px;
  }

  .ai-adoption-in-space {
    padding: 15px;
  }

  .col-lg-5.col-md-5.col-sm-12.col-xs-12.ai-adoption-left {
    padding: 0;
  }

  .col-lg-7.col-md-7.col-sm-12.col-xs-12.ai-adoption-right {
    padding: 0;
  }

  .internet-of-everything {
    padding: 15px;
  }

  .col-lg-5.col-md-5.col-sm-12.col-xs-12.internet-of-everything-left {
    padding: 0;
  }

  .col-lg-7.col-md-7.col-sm-12.col-xs-12.internet-of-everything-right {
    padding: 0;
  }

  .internet-of-everything-content h3 {
    font-size: 28px;
    margin-bottom: 30px;
    text-align: center;
    line-height: 1.5;
  }

  .internet-of-everything-left p {
    margin-bottom: 30px;
    text-align: justify;
  }

  .internet-of-everything-right img {
    width: 70%;
  }

  .killer-ai-project {
    padding: 15px;
  }

  .ai-adoption-content {
    margin-bottom: 0px;
    padding: 40px 30px;
  }

  .internet-of-everything-content {
    margin-bottom: 0px;
    padding: 40px 30px;
  }

  .killer-ai-project-left h3 {
    font-size: 28px;
    margin-bottom: 30px;
    text-align: center;
    line-height: 1.5;
  }

  .killer-ai-project-left p {
    margin-bottom: 30px;
    text-align: justify;
  }

  .killer-ai-project-right h3 {
    font-size: 28px;
    margin-bottom: 30px;
    text-align: center;
    line-height: 1.5;
  }

  .killer-ai-project-left img {
    margin-bottom: 30px;
  }

  .killer-ai-project-right img {
    margin-bottom: 30px;
  }

  .killer-ai-project-right p {
    margin-bottom: 30px;
    text-align: justify;
  }

  .ai-adoption-right img {
    margin-top: 15px;
  }

  .killer-ai-project-left-green h3 {
    font-size: 28px;
    line-height: 1.5;
    margin-bottom: 30px;
    text-align: center;
  }

  .killer-ai-project-left-green p {
    margin-bottom: 30px;
    text-align: justify;
  }

  .killer-ai-project {
    margin-bottom: 30px;
  }

  .workplace-support-perish-left {
    margin-bottom: 10px;
  }

  .assiatant-press-release-left .internet-of-everything-right img {
    width: 100%;
    margin-top: 15px;
  }

  .assiatant-press-release-left {
    margin-bottom: 40px;
  }

  .killer-ai-project-padding .workplace-support-perish-left {
    margin-bottom: 40px;
  }

  .internet-is-it-everything h3 {
    text-align: center;
  }

  .ai-adoption-project-left h3 {
    text-align: center;
  }

  .intelligent-automation-assistant-left h3 {
    text-align: center;
  }

  .what-is-assistant-left h3 {
    text-align: center;
  }

  .why-assistant-left h3 {
    text-align: center;
  }

  .assiatant-press-release-left h3 {
    text-align: center;
  }

  .workplace-support-perish-left h3 {
    text-align: center;
  }

  .col-lg-5.col-md-12.col-sm-12.col-xs-12.internet-of-everything-left {
    padding: 0;
  }

  .col-lg-7.col-md-12.col-sm-12.col-xs-12.internet-of-everything-right {
    padding: 0;
  }

  .col-lg-5.col-md-12.col-sm-12.col-xs-12.ai-adoption-left {
    padding: 0;
  }

  .col-lg-7.col-md-12.col-sm-12.col-xs-12.ai-adoption-right {
    padding: 0;
  }

  .--footer-border .--quick-link:last-child h4::after {
    display: none;
  }
}

/*** 

====================================================================
	Blogs Details Page Responsive for Landscape and Portrait
====================================================================

    ***/

@media (min-width: 320px) and (max-width: 480px) {
  .space-explorations-content h1 {
    line-height: 1.5;
    font-size: 32px;
    padding: 0;
    margin-bottom: 20px;
  }

  .space-explorations h6 {
    font-size: 16px;
    margin-bottom: 30px;
  }

  .space-explorations {
    padding: 40px 0 40px 0;
  }

  .space-explorations p {
    font-size: 18px;
    line-height: 30px;
    text-align: justify;
    margin-bottom: 30px;
    padding: 0;
  }

  .blog_details_bg {
    background: linear-gradient(180deg, #ffffdb 80%, #ffffff 20%);
  }

  .statistics-boggle-content p {
    font-size: 16px;
    line-height: 30px;
    text-align: justify;
    margin-bottom: 25px;
    padding: 0;
  }

  .statistics-boggle-content h3 {
    font-size: 24px;
    margin-bottom: 30px;
    padding: 0;
    text-align: center;
    line-height: 1.5;
  }

  .statistics-boggle-content h2 {
    padding: 0;
    text-align: center;
  }

  .whooping-percent-content h4 {
    font-size: 28px;
    line-height: 1.5;
    padding: 40px 20px;
  }

  ul.statistics-boggle-list {
    padding-bottom: 30px;
    width: 100%;
  }

  ul.statistics-boggle-list li {
    margin-bottom: 10px;
  }

  .row-text-content h5 {
    font-size: 14px;
  }

  .statistics-boggle-content span {
    font-size: 12px;
  }

  .row-social-content {
    padding: 30px 0 0;
    text-align: center;
  }

  .john-joe-list {
    padding: 30px 0;
    margin-bottom: 20px;
  }

  ul.killer-boggle-list {
    width: 100%;
  }

  .read-next-content {
    margin-bottom: 10px;
  }
}

@media (min-width: 481px) and (max-width: 767px) {
  .space-explorations-content h1 {
    line-height: 1.5;
    font-size: 32px;
    padding: 0;
    margin-bottom: 20px;
  }

  ul.killer-boggle-list {
    width: 100%;
  }

  .statistics-boggle-content h2 {
    padding: 0;
    text-align: center;
  }

  .space-explorations h6 {
    font-size: 16px;
    margin-bottom: 30px;
  }

  .space-explorations {
    padding: 40px 0 40px 0;
  }

  .space-explorations p {
    font-size: 18px;
    line-height: 30px;
    text-align: justify;
    margin-bottom: 30px;
    padding: 0;
  }

  .blog_details_bg {
    background: linear-gradient(180deg, #ffffdb 80%, #ffffff 20%);
  }

  .statistics-boggle-content p {
    font-size: 16px;
    line-height: 30px;
    text-align: justify;
    margin-bottom: 25px;
    padding: 0;
  }

  .statistics-boggle-content h3 {
    font-size: 24px;
    margin-bottom: 30px;
    padding: 0;
    text-align: center;
    line-height: 1.5;
  }

  .whooping-percent-content h4 {
    font-size: 28px;
    line-height: 1.5;
    padding: 40px 20px;
  }

  ul.statistics-boggle-list {
    padding-bottom: 30px;
    width: 100%;
  }

  ul.statistics-boggle-list li {
    margin-bottom: 10px;
  }

  .row-text-content h5 {
    font-size: 14px;
  }

  .statistics-boggle-content span {
    font-size: 14px;
  }

  .row-social-content {
    padding: 30px 0 0;
    text-align: center;
  }

  .john-joe-list {
    padding: 30px 0;
    margin-bottom: 20px;
  }

  .read-next-content {
    margin-bottom: 10px;
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .space-explorations-content h1 {
    line-height: 1.5;
    font-size: 32px;
    padding: 0;
    margin-bottom: 20px;
  }

  ul.killer-boggle-list {
    width: 100%;
  }

  .statistics-boggle-content h2 {
    padding: 0;
    text-align: center;
  }

  .space-explorations h6 {
    font-size: 16px;
    margin-bottom: 30px;
  }

  .space-explorations {
    padding: 40px 0 40px 0;
  }

  .space-explorations p {
    font-size: 18px;
    line-height: 30px;
    text-align: justify;
    margin-bottom: 30px;
    padding: 0;
  }

  .blog_details_bg {
    background: linear-gradient(180deg, #ffffdb 80%, #ffffff 20%);
  }

  .statistics-boggle-content p {
    font-size: 16px;
    line-height: 30px;
    text-align: justify;
    margin-bottom: 25px;
    padding: 0;
  }

  .statistics-boggle-content h3 {
    font-size: 26px;
    margin-bottom: 30px;
    padding: 0;
    text-align: center;
    line-height: 1.5;
  }

  .whooping-percent-content h4 {
    font-size: 28px;
    line-height: 1.5;
    padding: 40px 20px;
  }

  ul.statistics-boggle-list {
    padding-bottom: 30px;
    width: 100%;
    padding: 0 4em;
    margin-bottom: 40px;
  }

  ul.statistics-boggle-list li {
    margin-bottom: 10px;
  }

  .row-text-content h5 {
    font-size: 14px;
  }

  .statistics-boggle-content span {
    font-size: 14px;
  }

  .row-social-content {
    padding: 30px 0 0;
    text-align: center;
  }

  .john-joe-list {
    padding: 30px 0;
    margin-bottom: 20px;
  }

  .read-next-content {
    margin-bottom: 10px;
  }

  ul.killer-boggle-list li {
    text-align: justify;
    line-height: 30px;
  }

  ul.killer-boggle-list {
    padding: 0 5em;
    margin-bottom: 40px;
  }

  .space-explorations-content img {
    margin: 20px 0;
  }
}

/*** 

====================================================================
	Privacy Policy Page Responsive for Landscape and Portrait
====================================================================

    ***/

@media (min-width: 320px) and (max-width: 480px) {
  .privacy-policy {
    margin-bottom: 0px;
  }
}

@media (min-width: 481px) and (max-width: 767px) {
  .privacy-policy {
    margin-bottom: 0px;
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .space-explorations {
    padding: 40px 0 20px 0;
  }

  .statistics-boggle-content p {
    padding: 0 2em;
    text-align: justify;
    line-height: 30px;
  }

  .statistics-boggle-content h3 {
    padding: 0 1em;
  }

  .privacy-policy {
    margin-bottom: 0px;
  }
}

/*** 

====================================================================
	Blog Page Responsive for Landscape and Portrait
====================================================================

    ***/

@media (min-width: 320px) and (max-width: 480px) {
  .workplace-support-perish-left h3 {
    font-size: 24px;
    line-height: 1.5;
    margin-bottom: 30px;
    text-align: center;
  }

  .workplace-support-perish-left p {
    text-align: justify;
  }

  .assiatant-press-release-left h3 {
    font-size: 24px;
    line-height: 1.5;
    margin-bottom: 30px;
    text-align: center;
  }

  .assiatant-press-release-left p {
    text-align: justify;
  }

  .why-assistant-left h3 {
    font-size: 24px;
    line-height: 1.5;
    margin-bottom: 30px;
    text-align: center;
  }

  .why-assistant-left p {
    text-align: justify;
  }

  .what-is-assistant-left h3 {
    font-size: 24px;
    line-height: 1.5;
    margin-bottom: 30px;
    text-align: center;
  }

  .what-is-assistant-left p {
    text-align: justify;
  }

  .intelligent-automation-assistant-left h3 {
    font-size: 24px;
    line-height: 1.5;
    margin-bottom: 30px;
    text-align: center;
  }

  .intelligent-automation-assistant-left p {
    text-align: justify;
  }

  .internet-is-it-everything h3 {
    font-size: 24px;
    line-height: 1.5;
    margin-bottom: 30px;
    text-align: center;
  }

  .internet-is-it-everything p {
    text-align: justify;
  }

  .ai-adoption-project-left h3 {
    font-size: 24px;
    line-height: 1.5;
    margin-bottom: 30px;
    text-align: center;
  }

  .ai-adoption-project-left p {
    text-align: justify;
  }

  ul.killer-boggle-list li {
    text-align: justify;
    line-height: 30px;
  }

  .how-it-works-content h5 {
    font-size: 16px;
    font-weight: 500;
    text-align: center;
    color: #adbec6;
    font-family: "Roboto-Regular";
    margin-bottom: 40px;
    text-transform: uppercase;
    letter-spacing: 3px;
  }
}

@media (min-width: 481px) and (max-width: 767px) {
  .workplace-support-perish-left h3 {
    font-size: 24px;
    line-height: 1.5;
    margin-bottom: 30px;
    text-align: center;
  }

  .workplace-support-perish-left p {
    text-align: justify;
  }

  .assiatant-press-release-left h3 {
    font-size: 24px;
    line-height: 1.5;
    margin-bottom: 30px;
    text-align: center;
  }

  .assiatant-press-release-left p {
    text-align: justify;
  }

  .why-assistant-left h3 {
    font-size: 24px;
    line-height: 1.5;
    margin-bottom: 30px;
    text-align: center;
  }

  .why-assistant-left p {
    text-align: justify;
  }

  .what-is-assistant-left h3 {
    font-size: 24px;
    line-height: 1.5;
    margin-bottom: 30px;
    text-align: center;
  }

  .what-is-assistant-left p {
    text-align: justify;
  }

  .intelligent-automation-assistant-left h3 {
    font-size: 24px;
    line-height: 1.5;
    margin-bottom: 30px;
    text-align: center;
  }

  .intelligent-automation-assistant-left p {
    text-align: justify;
  }

  .internet-is-it-everything h3 {
    font-size: 24px;
    line-height: 1.5;
    margin-bottom: 30px;
    text-align: center;
  }

  .internet-is-it-everything p {
    text-align: justify;
  }

  .ai-adoption-project-left h3 {
    font-size: 24px;
    line-height: 1.5;
    margin-bottom: 30px;
    text-align: center;
  }

  .ai-adoption-project-left p {
    text-align: justify;
  }

  ul.killer-boggle-list li {
    text-align: justify;
    line-height: 30px;
  }

  .how-it-works-content h5 {
    font-size: 16px;
    font-weight: 500;
    text-align: center;
    color: #adbec6;
    font-family: "Roboto-Regular";
    margin-bottom: 40px;
    text-transform: uppercase;
    letter-spacing: 3px;
  }
}



/*** 

====================================================================
	Top Header
====================================================================

    ***/

.top-header-logo {
  padding: 0px !important;
  margin: 0px 0px;
  width: 100%;
  z-index: 9;
}

.top-header-logo img {
  width: 90px;
}

/* .top-header-logo ul li {
  padding: 10px 0px 10px 22px;
} */

.top-header-logo ul li a {
  font-size: 13px;
  color: #4e5257 !important;
  font-family: "Roboto-Regular";
  /* text-transform: uppercase; */
  padding: 0;
}

.top-header {
  border-bottom: 1px solid #f5f5f5;
  height: 36px;
  display: flex;
  align-items: center;
}

/*** 

====================================================================
	Pricing
====================================================================

    ***/
/* .Integrations_card img {
  width: 40px !important;
} */

.pricing-columns ul,
.pricing-columns li {
  margin: 0;
  padding: 0;
  list-style: none;
}

.pricing-columns .toggle,
.toggler {
  display: inline-block;
  vertical-align: middle;
  margin: 10px;
}

.pricing-columns .toggler {
  color: #adbec6;
  transition: 0.2s;
  font-weight: bold;
}

.pricing-columns .toggler--is-active {
  color: #333333;
}

.pricing-columns .toggle {
  position: relative;
  width: 80px;
  height: 35px;
  border-radius: 100px;
  background-color: #fff;
  overflow: hidden;
  border: 1px solid #2468f6;
}

.pricing-columns .check {
  position: absolute;
  display: block;
  cursor: pointer;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  z-index: 6;
}

.pricing-columns .check:checked ~ .switch {
  right: 2px;
  left: 57.5%;
  transition: 0.25s cubic-bezier(0.785, 0.135, 0.15, 0.86);
  transition-property: left, right;
  transition-delay: 0.08s, 0s;
}

.pricing-columns .switch {
  position: absolute;
  left: 2px;
  top: 2px;
  bottom: 2px;
  right: 57.5%;
  background-color: #2468f6;
  border-radius: 36px;
  z-index: 1;
  transition: 0.25s cubic-bezier(0.785, 0.135, 0.15, 0.86);
  transition-property: left, right;
  transition-delay: 0s, 0.08s;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
}

.pricing-section {
  text-align: center;
}

.pricing-section .toogle_left {
  text-align: right;
}

.pricing-columns .pricing-chart,
.pricing-columns-both .pricing-chart {
  margin-top: 30px;
  position: relative;
  width: 100%;
  float: left;
  margin-bottom: 0px;
  color: #333333;
}

.pricing-columns .ricing-chart .smaller-plans,
.pricing-columns-both .pricing-chart #smaller-plans {
  display: block;
}

.pricing-columns .pricing-chart .plan,
.pricing-columns-both .pricing-chart .plan {
  float: left;
  background-color: #fff;
  position: relative;
  color: #485a63;
  padding: 14px;
  border-right: 1px solid #e1e1e1;
  border-bottom: 1px solid #e1e1e1;
  border-top: 1px solid #e1e1e1;
  width: 25%;
}
.pricing-chart .smaller-plans{
  display: flex; 
  justify-content: space-between;
}

.pricing-columns .pricing-chart .plan:first-child,
.pricing-columns-both .pricing-chart .plan:first-child {
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
}

.pricing-columns .pricing-chart .plan:last-child,
.pricing-columns-both .pricing-chart .plan:last-child {
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
}

.pricing-columns .pricing-chart .plan h4,
.pricing-columns-both .pricing-chart .plan h4 {
  text-align: center;
  font-size: 22px;
  margin-bottom: 0px;
  font-family: "Roboto-Medium";
  margin-top: 0px;
  color: #333333;
  text-transform: uppercase;
}
.pricing-chart .users_upto {
  font-size: 12px;
  margin-bottom: 15px;
  width: 100%;
  float: left;
  font-family: "Roboto-Regular";
  color: #333333;
}
.plan.plan_nterprise .users_upto {
  visibility: hidden;
}
.pricing-columns .pricing-chart .plan:hover .users_upto {
  color: #fff;
}
.pricing-columns .pricing-chart .plan:hover h6,
.pricing-columns-both .pricing-chart .plan:hover h6 {
  color: #fff;
}

.mar-bottom-36px {
  margin-bottom: 75px !important;
}

.plan_nterprise h6 {
  visibility: hidden;
}

.pricing-columns .pricing-chart .plan svg,
.pricing-columns-both .pricing-chart .plan svg {
  width: 72px;
  margin-bottom: 15px;
  margin-top: 0px;
  height: 72px;
  fill: #2468f6;
}

.pricing-columns .pricing-chart .plan ul li span svg,
.pricing-columns-both .pricing-chart .plan ul li span svg {
  width: 14px;
  height: 14px;
  color: #b7b7b7;
  margin-bottom: 0;
  margin-left: 3px;
  cursor: pointer;
}

.pricing-columns .pricing-chart .plan ul li b,
.pricing-columns-both .pricing-chart .plan ul li b {
  font-family: "Roboto-Bold";
}

.pricing-columns .pricing-chart .plan:first-child,
.pricing-columns-both .pricing-chart .plan:first-child {
  border-left: 1px solid #e1e1e1;
}

.pricing-columns .pricing-chart .plan:before,
.pricing-columns .pricing-chart .plan .price,
.pricing-columns-both .pricing-chart .plan .price {
  font-size: 30px;
  float: left;
  width: 100%;
  margin-bottom: 40px;
  font-family: "Roboto-Medium";
  color: #333333;
  margin-top: 40px;
}

.pricing-columns .pricing-chart .plan:before,
.pricing-columns .pricing-chart .plan .price span,
.pricing-columns-both .pricing-chart .plan .price span {
  font-size: 14px;
  font-family: "Roboto-Regular";
}

.pricing-columns .pricing-chart .plan .price .dollar,
.pricing-columns-both .pricing-chart .plan .price .dollar {
  top: -10px;
  letter-spacing: -1px;
  left: 3px;
}

.pricing-columns .pricing-chart .plan .price .amount,
.pricing-columns-both .pricing-chart .plan .price .amount {
  font-size: 30px;
  font-weight: 600;
}

.pricing-columns .pricing-chart .plan .price .slash,
.pricing-columns-both .pricing-chart .plan .price .slash {
  font-weight: 600;
  left: -2px;
}

.pricing-columns .pricing-chart .plan .price .month,
.pricing-columns-both .pricing-chart .plan .price .month {
  font-weight: 600;
  left: -7px;
}

.pricing-columns .pricing-chart .plan ul li,
.pricing-columns-both .pricing-chart .plan ul li {
  font-weight: normal;
  font-size: 15px;
  text-align: left;
  margin-bottom: 10px;
  color: #333333;
  font-family: "Roboto-Regular";
  width: 100%;
  float: left;
}

.pricing-columns .pricing-chart .plan ul li label,
.pricing-columns-both .pricing-chart .plan ul li label {
  width: 7px;
  height: 7px;
  border-radius: 50%;
  border: 1px solid #2468f6;
  margin-bottom: 0;
  display: inline-block;
  position: relative;
  top: -1px;
  margin-right: 5px;
}

.pricing-columns .pricing-chart .plan a.sign-up,
.pricing-columns-both .pricing-chart .plan a.sign-up {
  width: 58%;
  padding: 0px;
  background-color: #fff;
  border: 1px solid #2468f6;
  color: #2468f6;
  font-size: 15px;
  letter-spacing: 0;
  margin: 0 auto;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  text-transform: uppercase;
  margin-bottom: 20px;
}

.pricing-section .sign-up {
  padding: 17px 50px;
  text-transform: uppercase;
  background-color: #93cc76;
  color: white;
  letter-spacing: 0.1em;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  -o-border-radius: 3px;
  border-radius: 3px;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  text-decoration: none;
  display: inline-block;
  white-space: pre;
  padding-left: 100px;
  padding-right: 100px;
  margin-top: 30px;
}

.pricing-columns .hide {
  display: none;
}

.pricing-columns .pricing-chart .plan:hover,
.pricing-columns-both .pricing-chart .plan:hover {
  background: #698ffe;
  border: 1px solid #698ffe;
  cursor: pointer;
  -ms-transform: scale(1.03);
  -webkit-transform: scale(1.03);
  transform: scale(1.03);
  transition: transform 2s linear;
  z-index: 9;
  -webkit-transition: transform 2s;
  -ms-transition: transform 2s;
  border-radius: 2s;
  box-shadow: 0px 10px 30px #00000029;
}

.pricing-columns h1 {
  line-height: 1.29;
  letter-spacing: -0.2px;
  text-align: center;
  color: #333333;
  font-family: "Roboto-Bold";
  font-size: 56px;
  margin-top: 60px;
  margin-bottom: 10px;
}

.full_comaparision h1 {
  line-height: 1.29;
  letter-spacing: -0.2px;
  text-align: center;
  color: #193A5D;
  font-family: "Roboto-MEdium";
  font-size: 26px;
  margin-bottom: 10px;
  margin-top: 30px;
}
.full_comaparision._assistant_pricing h1{
  margin-top:0px;
}
.full_comaparision._assistant_pricing{
  margin-top:10px;
}
.container_trial .full_comaparision p {
  font-family: "Roboto-Regular";
  font-size: 22px;
  line-height: 1.67;
  text-align: center;
  color: #000;
  margin-bottom: 20px;
  text-align:center;
}
.show_button_full_comaparison button:hover{
  background-color: #fff;
  border: 1px solid #2468f6;
  color: #2468f6 !important;
}
.show_button_full_comaparison button:focus{
  background-color: #fff;
  border: 1px solid #2468f6;
  color: #2468f6 !important;
}
.show_button_full_comaparison button:active{
  background-color: #fff !important;
  border: 1px solid #2468f6;
  color: #2468f6 !important;
}
.pricing-columns p {
  font-family: "Roboto-Regular";
  font-size: 18px;
  line-height: 1.67;
  text-align: center;
  color: #485a63;
}

.pricing-columns p:last-child {
  text-decoration: underline;
  color: #3366ff;
  margin-bottom: 20px;
}

.pricing-columns p a {
  text-decoration: underline;
  color: #3366ff;
  margin-bottom: 20px;
}

.pricing-columns h4 {
  font-family: "Roboto-Medium";
  font-size: 22px;
  line-height: 1.67;
  text-align: center;
  color: #485a63;
}

.pricing-section .start-free-trail {
  margin-bottom: 20px;
  cursor: pointer;
  margin-top: 30px;
  width: 100%;
  float: left;
}

.pricing-columns .pricing-chart .plan:hover h4,
.pricing-columns-both .pricing-chart .plan:hover h4 {
  color: #fff;
}

.pricing-columns .pricing-chart .plan:hover svg,
.pricing-columns-both .pricing-chart .plan:hover svg {
  fill: #fff;
}

.pricing-columns .pricing-chart .plan:hover ul li,
.pricing-columns-both .pricing-chart .plan:hover ul li {
  color: #fff;
}

.pricing-columns .pricing-chart .plan:hover ul li label,
.pricing-columns-both .pricing-chart .plan:hover ul li label {
  border: 1px solid #fff;
}

.pricing-columns .pricing-chart .plan:hover ul li span svg,
.pricing-columns-both .pricing-chart .plan:hover ul li span svg {
  color: #fff;
}

.pricing-columns .pricing-chart .plan:hover .price,
.pricing-columns-both .pricing-chart .plan:hover .price {
  color: #fff;
}

.pricing-columns .pricing-chart .plan:hover a.sign-up,
.pricing-columns-both .pricing-chart .plan:hover a.sign-up {
  background-color: #fff;
  border: 1px solid #fff;
  color: #2468f6;
  box-shadow: 0px 3px 6px #00000029;
}

.assistant_pricing {
  width: 100%;
  float: left;
}

/* Full comaparision pricing */

.full_comaparision {
  width: 100%;
  float: left;
  margin-bottom: 40px;
  margin-top:20px;
}

.show_button_full_comaparison button {
  background-color: #fff;
  border: 1px solid #2468f6;
  color: #2468f6;
  font-size: 18px;
  letter-spacing: 0;
  height: 50px;
  border-radius: 4px;
  text-transform: uppercase;
  margin: 0 auto;
  display: block;
  margin-bottom: 0px;
  width: 25%;
}

.hide_button_full_comaparison button {
  background-color: #fff;
  border: 1px solid #2468f6;
  color: #2468f6;
  font-size: 15px;
  letter-spacing: 0;
  height: 50px;
  border-radius: 6px;
  text-transform: uppercase;
  margin: 0 auto;
  display: block;
  margin-bottom: 40px;
  width: 20%;
}

.tr_bg {
  background: #FCFCFC;
}
thead .tr_bg {
  background: #EDF1FF 0% 0% no-repeat padding-box;
  border-bottom: 1px solid #D5D5D5;
  border-radius: 10px 10px 0px 0px;
  height:60px;
}
.table_full_comapare .table tbody tr th{
  font-family: "Roboto-Medium";
    font-size: 14px;
    line-height: 1.67;
    text-align: center;
    color: #000;
    margin-bottom: 20px;
    text-align: center;
}
.table_full_comapare .table tbody tr td{
  font-family: "Roboto-Regular";
    font-size: 14px;
    line-height: 1.67;
    text-align: center;
    color: #000;
    margin-bottom: 20px;
    text-align: center;
    vertical-align: middle;
}
.table_full_comapare .table thead tr th{
  font-family: "Roboto-Regular";
    font-size: 16px;
    line-height: 1.67;
    text-align: center;
    color: #000;
    margin-bottom: 20px;
    text-align: center;
    background: #EDF1FF;
}
.table_full_comapare .table-bordered td{
  border: 1px solid #d5d5d5 !important;
}
.table_full_comapare .table-bordered th{
  border: none;
}
.table_full_comapare .table tr th {
  width: 100%;
  padding: 10px;
  vertical-align: middle;
  text-align: center;
}
.table_full_comapare .table tr th:nth-child(1){
  width: 10%;
  padding: 10px;
  vertical-align: middle;
  text-align: left;
}
.table_full_comapare .table tr th:nth-child(2) {
  width: 50%;
  padding: 10px;
  vertical-align: middle;
  text-align: left;
}
.table_full_comapare .table tr th:nth-child(3) {
  width: 180px;
  padding: 10px;
  vertical-align: middle;
  text-align: center;
}
.table_full_comapare .table tr th:nth-child(4) {
  width: 180px;
  padding: 10px;
  vertical-align: middle;
  text-align: center;
}
.table_full_comapare .table tr th:nth-child(5) {
  width: 180px;
  padding: 10px;
  vertical-align: middle;
  text-align: center;
}
.table_full_comapare .table tr th:nth-child(6) {
  width: 180px;
  padding: 10px;
  vertical-align: middle;
  text-align: center;
}

.table_full_comapare .table tr td svg {
  width: 20px;
  height: 20px;
  margin: 0 auto;
  fill: #33906B;
}

._assistant_pricing_wrapper .table_full_comapare .table tr td .support_correct_svg{
 fill: #2468F6;
}
._assistant_pricing_wrapper .table_full_comapare .table tr td .support_error_svg {
  fill: #FF3131;
}

.table_full_comapare .table tr td:nth-child(1){
  text-align: left;
}
.table_full_comapare .table tr td:nth-child(2){
  text-align: left;
  white-space: pre-wrap;
  width: 47%;
}

.table_full_comapare .table tr td {
  text-align: center;
  white-space: nowrap;
}

.table_full_comapare .table tr td .support_error_svg {
  width: 20px;
  height: 20px;
  margin: 0 auto;
  display: block;
  fill: #FF3131;
}

.table_full_comapare .table tr td span svg {
  width: 12px;
  height: 12px;
  color: #999;
  margin-bottom: 0;
  cursor: pointer;
  position: relative;
  top: 0px;
}
.top_section.top_section_bottom.pricing_page{
  background: #F8F8F8 0% 0% no-repeat padding-box;
}
.top_section.top_section_bottom.pricing_page .main-slider-left {
  padding: 0px;
}
.top_section.top_section_bottom.pricing_page .main-slider-left h1{
  text-align: left !important;
  font-family: "Roboto-Medium";
}
.top_section.top_section_bottom.pricing_page .main-slider-left h6{
  text-align: left;
  font-family: "Roboto-Medium";
  color:#193A5D;
  font-size: 24px;
  margin-bottom:8px;
}
.container_trial .top_section.top_section_bottom.pricing_page .main-slider-left p{
  text-align: left;
  color:#193A5D;
}

/* START TOOLTIP STYLES */
[tooltip] {
  position: relative;
}

/* Applies to all tooltips */
[tooltip]::before,
[tooltip]::after {
  text-transform: none;
  font-size: 0.9em;
  line-height: 1;
  user-select: none;
  pointer-events: none;
  position: absolute;
  display: none;
  opacity: 0;
}

[tooltip]::before {
  content: "";
  border: 5px solid transparent;
  z-index: 1001;
}

[tooltip]::after {
  content: attr(tooltip);
  font-family: "Roboto-Regular";
  line-height: 20px;
  box-shadow: rgba(0, 0, 0, 0.16) 0 3px 6px;
  background: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 10px;
  color: #494949;
  z-index: 1000;
  width: 165px;
  max-width: max-content;
  white-space: pre-wrap;
}

/* Make the tooltips respond to hover */
[tooltip]:hover::before,
[tooltip]:hover::after {
  display: block;
}

/* don't show empty tooltips */
[tooltip=""]::before,
[tooltip=""]::after {
  display: none !important;
}

/* FLOW: UP */
[tooltip]:not([flow])::before,
[tooltip][flow^="up"]::before {
  bottom: 100%;
  border-bottom-width: 0;
  border-top-color: #fff;
}

[tooltip]:not([flow])::after,
[tooltip][flow^="up"]::after {
  bottom: calc(100% + 5px);
}

[tooltip]:not([flow])::before,
[tooltip]:not([flow])::after,
[tooltip][flow^="up"]::before,
[tooltip][flow^="up"]::after {
  left: 50%;
  transform: translate(-50%, -0.5em);
}

/* KEYFRAMES */
@keyframes tooltips-vert {
  to {
    opacity: 1;
    transform: translate(-50%, 0);
  }
}

@keyframes tooltips-horz {
  to {
    opacity: 1;
    transform: translate(0, -50%);
  }
}

/* FX All The Things */
[tooltip]:not([flow]):hover::before,
[tooltip]:not([flow]):hover::after,
[tooltip][flow^="up"]:hover::before,
[tooltip][flow^="up"]:hover::after,
[tooltip][flow^="down"]:hover::before,
[tooltip][flow^="down"]:hover::after {
  animation: tooltips-vert 300ms ease-out forwards;
}

[tooltip][flow^="left"]:hover::before,
[tooltip][flow^="left"]:hover::after,
[tooltip][flow^="right"]:hover::before,
[tooltip][flow^="right"]:hover::after {
  animation: tooltips-horz 300ms ease-out forwards;
}

/*** 

====================================================================
	Pricing Responsive
====================================================================

***/
@media only screen and (max-width: 1540px) {
  .pricing-columns .pricing-chart .plan,
  .pricing-columns-both .pricing-chart .plan {
    padding: 0px 10px;
  }

  .pricing-columns .pricing-chart .plan ul li,
  .pricing-columns-both .pricing-chart .plan ul li {
    font-size: 12px;
  }
}

@media only screen and (max-width: 1200px) {
  .pricing-columns .pricing-chart .plan,
  .pricing-columns-both .pricing-chart .plan {
    width: 43%;
    float: left;
    padding: 10px;
    margin: 20px 30px;
    border: 1px solid #e1e1e1;
  }

  .pricing-columns .pricing-chart .plan ul,
  .pricing-columns-both .pricing-chart .plan ul {
    text-align: center;
    margin-left: 30px;
  }

  .show_button_full_comaparison button {
    width: 35%;
  }

  .hide_button_full_comaparison button {
    width: 35%;
  }

  .cognii_work_content .vir_assis_num {
    top: -10px !important;
  }
}

@media only screen and (max-width: 991px) {
  .pricing-columns .pricing-chart .plan,
  .pricing-columns-both .pricing-chart .plan {
    width: 50% !important;
    padding: 10px;
    margin: 20px auto;
    border: 1px solid #e1e1e1;
  }

  .pricing-columns .pricing-chart .plan ul,
  .pricing-columns-both .pricing-chart .plan ul {
    text-align: center;
    margin-left: 30px;
  }

  .show_button_full_comaparison button {
    width: 35% !important;
  }

  .hide_button_full_comaparison button {
    width: 35% !important;
  }

  .pricing-columns p {
    padding: 0px;
  }

  .table_full_comapare .table-bordered {
    border: 1px solid #dee2e6;
    overflow-x: auto;
    width: 100%;
    display: block;
  }

  .pricing-section .start-free-trail {
    margin-bottom: 30px;
  }
}

@media only screen and (max-width: 680px) {
  .pricing-chart .plan ul li,
  .pricing-columns-both .pricing-chart .plan ul li {
    font-size: 12px !important;
  }

  .mar-bottom-36px {
    margin-bottom: 74px !important;
  }

  .pricing-columns .pricing-chart .plan,
  .pricing-columns-both .pricing-chart .plan {
    width: 50% !important;
    padding: 10px;
    margin: 20px auto;
    border: 1px solid #e1e1e1;
  }

  .pricing-columns .pricing-chart .plan ul,
  .pricing-columns-both .pricing-chart .plan ul {
    text-align: center;
    margin-left: 15px;
  }

  .show_button_full_comaparison button {
    width: 70% !important;
    cursor: pointer;
  }

  .hide_button_full_comaparison button {
    width: 70% !important;
    cursor: pointer;
  }

  .pricing-columns h1 {
    font-size: 40px;
    margin-top: 0px;
    margin-bottom: 20px;
  }

  .pricing-columns p {
    padding: 0px;
  }
}

@media only screen and (max-width: 475px) {
  .pricing-columns .pricing-chart .plan,
  .pricing-columns-both .pricing-chart .plan {
    width: 100% !important;
    padding: 10px;
    border: 1px solid #e1e1e1;
    margin: 20px auto;
  }

  .pricing-columns .pricing-chart .plan ul,
  .pricing-columns-both .pricing-chart .plan ul {
    text-align: center;
    margin-left: 30px;
  }

  .show_button_full_comaparison button {
    width: 100% !important;
    cursor: pointer;
  }

  .hide_button_full_comaparison button {
    width: 100% !important;
    cursor: pointer;
  }

  .pricing-columns h1 {
    font-size: 40px;
    margin-top: 30px;
  }

  .pricing-columns p {
    font-size: 18px;
    padding: 0px;
  }

  .full_comaparision h1 {
    font-size: 30px;
  }

  .full_comaparision p {
    font-size: 18px;
    padding: 0px;
  }

  .pricing-section .toogle_left {
    text-align: center;
  }

  .table_full_comapare .table tr td {
    text-align: center;
    display: table-cell;
    vertical-align: middle;
  }
}

@media only screen and (min-width: 400px) and (max-width: 414px) {
  /* .cogni_fav_apps_color .card_office {
    margin: 21px !important;
  } */
}

/*** 

====================================================================
	Integrations New page
====================================================================

***/

.Integrations_card {
  margin-bottom: 30px;
  width: 100%;
  float: left;
}

.integrations_card_desk {
  border: 1px solid #dedede;
  width: 100%;
  float: left;
  border-radius: 8px;
  margin-bottom: 30px;
  text-decoration: none;
}

.integrations_card_desk:hover {
  text-decoration: none;
}

.card_head a:hover {
  color: rgb(23, 99, 255) !important;
}

.card_head a:focus {
  color: rgb(23, 99, 255) !important;
}

.card_head a:visited {
  color: rgb(23, 99, 255) !important;
}

.card_head .wv-more-txt {
  color: rgb(23, 99, 255) !important;
}

.integrations_card_desk .card_head {
  background: #f4f4f4;
  text-align: left;
  padding: 25px;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}

.integrations_slider_card img {
  padding-bottom: 0px;
  padding: 5em 0;
}

.integrations_card_desk .card_head h5 {
  font-size: 18px;
  font-family: "Roboto-Medium";
  color: #494949;
}

.integrations_card_desk .card_head p {
  font-size: 15px;
  font-family: "Roboto-Regular";
  color: #494949;
  margin-bottom: 0px;
}

.card_office {
  margin: 25px auto;
  width: 80px;
  height: 80px;
  display: flex;
  align-items: center;
  border-radius: 8px;
  /* box-shadow: rgba(0, 0, 0, 0.16) 0 3px 6px; */
}

.card_office svg {
  width: 48px;
  height: 48px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  fill: #fff;
}

.service_now_desk {
  background: #2d3d3f;
}

.slack_desk {
  background: #441f49;
}

.fresh_desk {
  background: #3fb870;
}

.fresh_desk svg {
  width: 96px;
  height: 96px;
}

.slack_desk svg {
  width: 80px;
  height: 80px;
}

.ibm_desk {
  background: #1f70c1;
}

.ibm_desk svg {
  color: #fff;
}

.twilio_desk {
  background: #cf272d;
}

.ad_Desk {
  background: #6a8ffa;
}

.zen_desk {
  background: #04353d;
}

.zen_desk svg {
  width: 44px;
  height: 44px;
}

.box_desk {
  background: #3e84c0;
}

/*** 

====================================================================
	Integrations New page Mobile Responsive
====================================================================

***/
@media (max-width: 1100px) and (min-width: 1000px) {
  .Integrations_card .col-lg-3 {
    -ms-flex: 0 0 33.33%;
    flex: 0 0 33.33%;
    max-width: 33.33%;
  }

  .Integrations_card.solutions_integrations_card .col-lg-3 {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }
}

@media (max-width: 768px) {
  .integrations_card_desk .card_head p {
    font-size: 13px;
    min-height: 0px;
  }

  .integrations_slider_card img {
    padding-top: 0px;
  }
}

/*** 

====================================================================
	Cognidesk new page design
====================================================================

***/
.cogni_workspace_content {
  box-shadow: rgba(0, 0, 0, 0.16) 0 0px 6px;
  padding: 20px;
  border-radius: 8px;
  text-align: center;
  margin-top: 20px;
  margin-bottom: 20px;
}

.reinvent_workplace .free_trial {
  background: #2468f6;
  color: #fff;
  border-radius: 8px;
  width: auto;
  text-align: center;
  padding: 10px 20px;
}

.reinvent_workplace .cogni_workspace_content img {
  margin-top: 20px;
}

.reinvent_workplace {
  margin-top: 0px;
}

.cogni_workspace_content h3 {
  margin-bottom: 10px;
  font-size: 17px;
}

.cogni_workspace_content p {
  margin-bottom: 0px;
  font-size: 15px;
  line-height: 25px;
  min-height: 110px;
}

.cogni_play_youtube {
  width: 100%;
  float: left;
  margin: 60px 0px;
  box-shadow: rgba(0, 0, 0, 0.16) 0 3px 6px;
  padding: 10px;
}

.cogni_play_youtube iframe {
  width: 1090px;
  float: left;
  height: 613px;
}

.cognii_work_content .cogni_works h1 {
  color: #263238;
  font-size: 40px;
  font-family: "Roboto-Bold";
  text-align: center;
  margin-bottom: 20px;
}

.cognii_work_content .cogni_works p {
  color: #485a63;
  font-size: 18px;
  font-family: "Roboto-Regular";
  text-align: center;
  margin-bottom: 120px;
}

.cognii_work_content {
  margin-bottom: 60px;
  width: 100%;
  float: left;
  background: #f4f4f4;
  padding-top: 60px;
  padding-bottom: 80px;
  margin-top: 0px;
}

.cognii_work_content .benefits-workplace-content {
  background: #f4f4f4;
}

.cognii_work_content .benefits-workplace-content img {
  margin-bottom: 20px;
  width: 50%;
  height: auto;
  margin-top: 40px;
}

.cognii_work_content .cogni_workspace_content p {
  margin-bottom: 0px;
  font-size: 15px;
  line-height: 25px;
  min-height: 100px;
}

.assiatant_learn_more a {
  width: 100%;
  margin: 0 auto;
  display: block;
  text-align: center;
  text-decoration: none;
  font-family: "Roboto-Medium";
  font-size: 15px;
  color: #2e6dfa;
}

.assiatant_learn_more a:hover {
  color: #1757dc;
  text-decoration: none;
}

.cognii_work_content .vir_assis_num {
  position: absolute;
  top: -10px;
  left: 45%;
  background: #fff;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  border: 1px solid #ccc;
}

.cognii_work_content .vir_assis_num span {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #2b9ef7;
  font-size: 18px;
  font-family: "Roboto-Regular";
}

.cognii_work_content .cogni_workspace_content_before:before {
  content: "";
  position: absolute;
  top: -65px;
  left: 75%;
  background: url(../images/arrow_cogni.png);
  right: 0;
  width: 213px;
  height: 44px;
  background-repeat: no-repeat;
  background-size: 100% 100%;
}

.cogni_fav_apps {
  margin-bottom: 80px;
  position: relative;
  width: 100%;
  float: left;
}

.cogni_fav_apps_header h3 {
  color: #263238;
  font-size: 40px;
  font-family: "Roboto-Bold";
  text-align: center;
  margin-bottom: 20px;
}

.cogni_fav_apps_header h1 {
  color: #263238;
  font-size: 40px;
  font-family: "Roboto-Bold";
  text-align: center;
  margin-bottom: 20px;
}
.cogni_fav_apps_header p {
  color: #485a63;
  font-size: 18px;
  font-family: "Roboto-Regular";
  text-align: center;
  margin-bottom: 30px;
  padding: 0px 12rem;
}

.cogni_fav_apps_color {
  margin: 0 auto;
}

.cogni_fav_apps_color .card_office {
  background: red;
  margin: 15px;
  width: 60px;
  height: 60px;
  display: flex;
  align-items: center;
  border-radius: 8px;
  box-shadow: rgba(0, 0, 0, 0.16) 0 3px 6px;
  float: left;
}

.cogni_fav_apps_color .service_now_desk {
  background: #2d3d3f;
}

.cogni_fav_apps_color .card_office svg {
  width: 30px;
  height: 30px;
}

.cogni_fav_apps_color .slack_desk {
  background: #441f49;
}

.cogni_fav_apps_color .slack_desk svg {
  width: 50px;
  height: 50px;
}

.cogni_fav_apps_color .ibm_desk {
  background: #1f70c1;
}

.cogni_fav_apps_color .twilio_desk {
  background: #cf272d;
}

.cogni_fav_apps_color .ad_Desk {
  background: #6a8ffa;
}

.cogni_fav_apps_color .zen_desk {
  background: #04353d;
}

.cogni_fav_apps_color .box_desk {
  background: #3e84c0;
}

.cogni_fav_apps_color .freshdesk_desk {
  background: #3db86f;
}

.ibm_desk img {
  width: 30px;
  margin: 0 auto;
  display: block;
}

.fresh_service {
  background: #fff;
}

.fresh_service img {
  margin: 0 auto;
  display: block;
}

.box_desk img {
  width: 30px;
  margin: 0 auto;
  display: block;
}

.freshdesk_desk img {
  width: 55px;
  margin: 0 auto;
  display: block;
}

.service_now_desk img {
  width: 30px;
  margin: 0 auto;
  display: block;
}

.cogni_fav_apps_color .teams_desk {
  background: #5557af;
}

.cogni_self_service .cogni_fav_apps_header {
  background: #f4f4f4;
  border-radius: 8px;
  padding: 80px 40px;
}

/*** 

====================================================================
	Cognidesk new page design Mobile Responsive
====================================================================

***/
@media only screen and (max-width: 1100px) and (min-width: 1000px) {
  .cogni_play_youtube iframe {
    width: 99.5%;
    float: left;
    height: 509px;
  }
}

@media only screen and (max-width: 991px) {
  .cogni_play_youtube iframe {
    width: 100%;
    float: left;
    height: 400px;
  }

  .cognii_work_content .cogni_workspace_content_before:before {
    display: none;
  }

  .mt_30 {
    margin-top: 30px;
  }

  .cognii_work_content .cogni_works p {
    margin-bottom: 30px;
  }

  .cogni_fav_apps_header p {
    padding: 0px;
  }
}

@media only screen and (max-width: 768px) {
  .cogni_play_youtube iframe {
    width: 100%;
    float: left;
    height: 400px;
  }

  .cogni_fav_apps_color .card_office {
    margin: 10px;
  }

  .cognii_work_content .cogni_works p {
    margin-bottom: 30px;
  }

  .reinvent_workplace {
    margin-top: 40px;
  }
}

@media only screen and (max-width: 575px) {
  .cogni_play_youtube iframe {
    width: 100%;
    float: left;
    height: 200px;
  }

  .cognii_work_content .cogni_works p {
    margin-bottom: 30px;
  }

  .cogni_fav_apps_color .card_office {
    margin: 15px;
  }
}

/*** 

====================================================================
	Features new page design
====================================================================

***/

.cogai_helpdesk_ai {
  box-shadow: rgba(0, 0, 0, 0.16) 0 0px 6px;
  padding: 30px;
  border-radius: 8px;
  text-align: center;
  margin-top: 30px;
}

.cogai_helpdesk {
  margin: 0px 0px 40px 0px;
}

.cogai_helpdesk h3 {
  font-size: 40px;
  font-family: "Gordita-Bold";
  text-align: center;
  color: #333333;
  margin-bottom: 40px;
}

.cogai_helpdesk_ai_left img {
  width: 100px;
  height: 100px;
}

.cogai_helpdesk_ai_left {
  display: flex;
  align-items: center;
}

.cogai_helpdesk_ai_right h3 {
  color: #333333;
  font-size: 18px;
  font-family: "Gordita-Bold";
  line-height: 1.3;
  text-align: left;
  margin-bottom: 10px;
}

.cogai_helpdesk_ai_right p {
  color: #333333;
  font-size: 15px;
  font-family: "Gordita-Regular";
  text-align: left;
  margin-bottom: 0px;
  min-height: 90px;
}

.suprcharging_benefits {
  background: #f3f3f3;
  padding: 40px 0px;
}

.suprcharging_benefits .cogni_workspace_content {
  box-shadow: rgba(0, 0, 0, 0.16) 0 0px 6px;
  padding: 20px;
  border-radius: 8px;
  text-align: center;
  margin-top: 20px;
  margin-bottom: 20px;
  background: #fff;
}

.suprcharging_benefits .cogni_workspace_content p {
  min-height: 100px;
  font-size: 14px;
  margin-bottom: 0px;
}

.suprcharging_benefits .cogni_workspace_content img {
  width: 86px;
  height: 86px;
}

.suprcharging_benefits_start_right {
  padding-left: 0px !important;
  padding-top: 130px;
}

.suprcharging_benefits_start_right h4 {
  color: #333333;
  font-size: 26px;
  font-family: "Roboto-Bold";
  line-height: 1.3;
  text-align: left;
  margin-bottom: 10px;
}

.suprcharging_benefits_start_right p {
  color: #333333;
  font-size: 18px;
  font-family: "Roboto-Regular";
  text-align: left;
  margin-bottom: 10px;
  padding-right: 5rem;
}

.suprcharging_benefits_start_left img {
  width: 100%;
  float: left;
  display: flex;
  align-items: center;
  position: relative;
  top: 30px;
}

.suprcharging_benefits_start_left {
  display: flex;
  align-items: center;
}

.ibm_watson_feature {
  padding: 100px 0px;
}

.ibm_watson_feature_left h5 {
  color: #333333;
  font-size: 30px;
  font-family: "Roboto-Bold";
  text-align: left;
  margin-bottom: 30px;
}

.ibm_watson_feature_left p {
  color: #333333;
  font-size: 18px;
  font-family: "Roboto-Regular";
  text-align: left;
  margin-bottom: 30px;
}

.ibm_watson_feature_left {
  padding-top: 30px;
}

.ibm_watson_feature_left ul {
  padding-left: 15px;
  margin-top: 20px;
}

.ibm_watson_feature_left ul li {
  list-style: unset;
  padding-bottom: 10px;
  font-size: 15px;
  font-family: "Roboto-Regular";
}

.ibm_watson_feature img {
  width: 100%;
  float: left;
}

.ibm_watson_feature_left .start-free-trail {
  margin-top: 40px;
}

/*** 

====================================================================
	Cognidesk new page design Mobile Responsive
====================================================================

***/
@media only screen and (max-width: 1024px) and (min-width: 1000px) {
  .know-how-assistant-image {
    text-align: center;
  }

  .know-how-assistant-image img {
    width: 100%;
  }

  .cogni_workspace_content h3 {
    font-size: 16px;
  }

  .suprcharging_benefits .cogni_workspace_content p {
    min-height: 110px;
    font-size: 14px;
  }

  .suprcharging_benefits .cogni_workspace_content {
    padding: 20px 10px;
  }
}

@media only screen and (max-width: 991px) {
  .cogai_helpdesk h3 {
    margin-top: 0px;
    font-size: 30px;
    text-align: center;
  }

  #_form_6_ ._form-title h2 {
    margin-bottom: 15px !important;
  }

  .cogai_helpdesk_ai img {
    margin: 0 auto;
    display: block;
  }

  .cogai_helpdesk p {
    text-align: center;
  }

  .cogai_helpdesk_ai_right h3 {
    font-size: 20px;
  }

  .cogai_helpdesk_ai_right p {
    min-height: 120px;
  }

  .cogni_workspace_content h3 {
    margin-bottom: 10px;
    font-size: 15px;
  }

  .ibm_watson_feature_left h5 {
    text-align: center;
  }

  .ibm_watson_feature_left p {
    text-align: center;
  }

  .ibm_watson_feature_left ul {
    padding-left: 170px;
  }

  .ibm_watson_feature_left .start-free-trail {
    text-align: center;
    margin-bottom: 60px;
  }

  .ibm_watson_feature_right {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .ibm_watson_feature_right img {
    width: 60%;
  }

  .cogai_helpdesk_ai_right h3 {
    margin-top: 20px;
  }

  .suprcharging_benefits_start_right p {
    padding-right: 0px;
  }

  .suprcharging_benefits .cogni_workspace_content p {
    min-height: 130px;
    font-size: 14px;
  }
}

@media only screen and (max-width: 675px) {
  .suprcharging_benefits_start_left img {
    display: none;
  }

  .suprcharging_benefits_start_right h4 {
    text-align: center;
    margin-bottom: 20px;
  }

  .suprcharging_benefits_start_right p {
    text-align: center;
    font-size: 14px;
    padding: 0px 20px;
  }

  .suprcharging_benefits_start_right {
    padding-top: 0px;
  }

  .ibm_watson_feature_left h5 {
    font-size: 24px;
    margin-bottom: 20px;
  }

  .ibm_watson_feature_left p {
    font-size: 15px;
    margin-bottom: 10px;
    padding: 0px 20px;
  }

  .ibm_watson_feature_left ul {
    padding-left: 100px;
  }

  .ibm_watson_feature_right img {
    width: 80%;
  }

  .ibm_watson_feature {
    padding: 50px 0px;
  }

  .suprcharging_benefits {
    padding: 50px 0px;
  }

  .suprcharging_benefits .cogni_workspace_content p {
    min-height: 125px;
    font-size: 14px;
  }

  .cogai_helpdesk_ai_right p {
    min-height: 125px;
    font-size: 14px;
  }

  .cogai_helpdesk_ai_right h3 {
    font-size: 15px;
  }

  .main-slider-right img {
    width: 60%;
  }
}

@media only screen and (max-width: 575px) {
  .suprcharging_benefits_start_left img {
    display: none;
  }

  .suprcharging_benefits_start_right h4 {
    text-align: center;
  }

  .suprcharging_benefits_start_right p {
    text-align: center;
    font-size: 14px;
    padding: 0px 20px;
  }

  .suprcharging_benefits_start_right {
    padding-top: 0px;
  }

  .ibm_watson_feature_left h5 {
    font-size: 24px;
    margin-bottom: 20px;
  }

  .ibm_watson_feature_left p {
    font-size: 15px;
    margin-bottom: 10px;
    padding: 0px 20px;
  }

  .ibm_watson_feature_left ul {
    padding-left: 20px;
  }

  .ibm_watson_feature_right img {
    width: 80%;
  }

  .ibm_watson_feature {
    padding: 50px 0px;
  }

  .suprcharging_benefits {
    padding: 50px 0px;
  }

  .main-slider-right img {
    width: 80% !important;
  }
}

@media only screen and (max-width: 350px) {
  .cogni_self_service .cogni_fav_apps_header {
    padding: 80px 0px;
  }

  .cogni_fav_apps_color .card_office {
    margin: 23px;
  }
}

/*** 

====================================================================
	IBM Integration new page design
====================================================================

***/
.ibm_inte_header h4 {
  font-size: 24px;
  color: #485a63;
  font-family: "Roboto-Medium";
  line-height: 1.5;
  margin-bottom: 20px;
  text-align: left;
}

.watso_combine_box {
  box-shadow: rgba(0, 0, 0, 0.16) 0 0px 6px;
  border-radius: 8px;
  text-align: center;
  margin-top: 30px;
  padding: 80px 30px;
  margin-bottom: 80px;
}

.watson_combine_content h4 {
  margin-top: 40px;
  font-size: 30px;
  color: #485a63;
  font-family: "Roboto-Medium";
  line-height: 1.5;
  margin-bottom: 30px;
  text-align: left;
}

.watson_combine_content ul {
  padding-left: 20px;
  text-align: left;
}

.watson_combine_content ul li {
  list-style: unset;
  color: #485a63;
  font-family: "Roboto-Regular";
  margin-bottom: 10px;
}

.watson_combine_content_left h4 {
  margin-top: 0px;
  font-size: 30px;
  color: #485a63;
  font-family: "Roboto-Medium";
  line-height: 1.5;
  margin-bottom: 30px;
  text-align: left;
}

.watson_combine_content_left p {
  font-size: 18px;
  color: #485a63;
  font-family: "Roboto-Regular";
  line-height: 1.5;
  margin-bottom: 30px;
  text-align: left;
}

.watson_combine_content_img img {
  max-width: 100% !important;
}

.watson_combine_content_right h4 {
  margin-top: 0px;
  font-size: 30px;
  color: #485a63;
  font-family: "Roboto-Medium";
  line-height: 1.5;
  margin-bottom: 30px;
  text-align: left;
}

.ai_self_start h3 {
  font-size: 20px;
  font-family: "Roboto-Medium";
  margin-bottom: 10px;
  color: #485a63;
}

.watson_combine_content_right p {
  font-size: 18px;
  color: #485a63;
  font-family: "Roboto-Regular";
  line-height: 1.5;
  margin-bottom: 30px;
  text-align: left;
}

.watson_combine_content_right ul {
  padding-left: 20px;
  text-align: left;
}

.watson_combine_content_right ul li {
  list-style: unset;
  color: #485a63;
  font-family: "Roboto-Regular";
  margin-bottom: 10px;
}

.watson_combine_ai_self_start {
  padding: 80px 60px !important;
}

.ai_self_start_center {
  text-align: left;
}

.ai_self_start_center img {
  width: 86px;
  height: 86px;
  margin-bottom: 40px;
  margin-top: 40px;
}

.ai_self_start h4 {
  margin-top: 0px;
  font-size: 30px;
  color: #485a63;
  font-family: "Roboto-Medium";
  line-height: 1.5;
  margin-bottom: 40px;
  text-align: left;
}

.ai_self_start p {
  font-size: 18px;
  color: #485a63;
  font-family: "Roboto-Regular";
  line-height: 1.5;
  text-align: left;
  margin-bottom: 0px;
}

.watson_combine_content_img {
  display: flex;
  align-items: center;
  justify-content: center;
}

.watso_combine_box ul li .a_tar_color {
  color: rgb(23, 99, 255) !important;
}

/*** 

====================================================================
	IBM Integration new page design Mobile Responsive
====================================================================

***/

@media only screen and (max-width: 767px) {
  .watson_combine_content_right h1 {
    font-size: 22px;
    text-align: center;
  }

  .watson_combine_content_right h4 {
    margin-top: 40px;
    font-size: 22px;
    text-align: center;
  }

  .watson_combine_ai_self_start {
    padding: 80px 30px !important;
  }

  .ibm_inte_header h4 {
    font-size: 22px;
    text-align: left;
  }

  .watson_combine_content h4 {
    font-size: 22px;
  }

  .watson_combine_content_left h4 {
    font-size: 22px;
  }

  .ai_self_start h4 {
    font-size: 22px;
  }
}

/*** 

====================================================================
	Security new page design  
====================================================================

***/
.mob_res_accordian .statistics-boggle-content h3 {
  font-family: "Gordita-Bold";
  font-size: 18px;
  letter-spacing: -0.2px;
  color: #333333;
  margin-bottom: 10px;
  padding: 0px;
  line-height: 1.67;
}

.mob_res_accordian .card {
  margin-bottom: 15px;
}

.mob_res_accordian .statistics-boggle-content p {
  font-family: "Gordita-Regular";
  font-size: 16px;
  line-height: 30px;
  text-align: left;
  color: #485a63;
  margin-bottom: 20px;
  padding: 0px;
}

.mob_res_accordian #accordion {
  width: 100%;
  float: left;
  margin-bottom: 80px;
}

.mob_res_accordian .btn-link:before {
  content: "\2212";
  position: absolute;
  top: 0;
  right: 0;
  float: right;
  font-size: 35px;

  margin-right: 30px;
  margin-top: 10px;
  color: #ff9f02;
}

.mob_res_accordian .collapsed:after {
  content: "\2b";
  position: absolute;
  top: 0;
  right: 0;
  float: right;
  font-size: 35px;
  align-items: center;
  justify-content: center;
  margin-right: 30px;
  margin-top: 10px;
  color: #999;
}

.mob_res_accordian .card-header {
  padding: 0.75rem 1.25rem;
  margin-bottom: 0;
  color: inherit;
  background-color: #f5f5f5;
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
}

.mob_res_accordian .btn-link.collapsed {
  color: #494949;
  width: 100%;
  float: left;
  font-family: "Roboto-Bold";
  text-decoration: none !important;
  font-size: 18px;
}

.mob_res_accordian .btn-link {
  font-weight: 400;
  color: #ff9f02;
  font-family: "Roboto-Medium";
  width: 100%;
  float: left;
  text-decoration: none !important;
  text-align: left;
  font-size: 18px;
}

.mob_res_accordian .btn-link:hover {
  color: #ff9f02;
  text-decoration: none;
  font-size: 18px;
}

.mob_res_accordian .automated_catalog_svg {
  margin-bottom: 60px;
}

.mob_res_accordian .sv-tab-panel h4 {
  font-size: 30px;
  font-family: "Roboto-Bold";
}

@media only screen and (max-width: 1100px) {
  .apps_integrations.apps_integrations_home .cc_feature_apps_header h3 {
    padding: 0px 15px;
    margin-bottom: 10px;
    font-size: 32px;
  }

  .assistant_slider.home_bg_header img {
    width: 100%;
  }

  .beta-button button {
    font-size: 14px !important;
  }

  .mob_res_accordian .btn-link {
    font-weight: 400;
    color: #ff9f02;
    font-family: "Roboto-Medium";
    width: 100%;
    float: left;
    text-decoration: none !important;
    text-align: left;
  }

  .header-logo ul li a {
    font-size: 14px;
    padding: 0px;
  }
}

/*** 

====================================================================
	Partner new page design  
====================================================================

***/
.patrner_forms .mb-3,
.patrner_forms .my-3 {
  margin-bottom: 10px !important;
}

.patrner_forms h4 {
  font-family: "Roboto-Bold";
  line-height: 1.3;
  color: #333333;
  font-size: 22px;
  margin-left: 25px;
  margin-bottom: 20px;
}

.partner_step h4 {
  position: absolute;
  margin-top: -55px;
  color: #333333;
  font-size: 22px;
  margin-bottom: 25px;
  font-family: "Gordita-Bold";
  line-height: 1.3;
  text-align: left;
}

.become_our_partner {
  padding-top: 40px;
  padding-bottom: 40px;
}

.become_our_partner h2 {
  font-size: 40px;
  text-align: left;
  color: #333333;
  margin-bottom: 40px;
  font-family: "Gordita-Bold";
}

.know-how-assistant-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.become_our_partner p {
  font-family: "Roboto-Regular";
  font-size: 18px;
  line-height: 1.54;
  letter-spacing: -0.2px;
  color: #485a63;
  margin-bottom: 40px;
}

.patrner_forms button {
  width: 150px;
  margin-left: 3px;
  margin-top: 10px;
  background: #2468f6;
  color: #fff;
  border-radius: 6px;
  text-align: center;
  padding: 10px 20px;
}

.patrner_forms label {
  font-family: "Roboto-Regular";
  font-size: 15px;
  margin-bottom: 5px;
}

.patrner_forms .form-control::placeholder {
  font-family: "Roboto-Regular";
  font-size: 15px;
}

.patrner_forms input {
  outline: none;
  box-shadow: none;
}

.patrner_forms input:focus {
  outline: none;
  box-shadow: none;
  border: 1px solid #ced4da;
}

.patrner_forms .form-control.is-invalid,
.was-validated .form-control:invalid {
  background-image: none !important;
  border: 1px solid #ced4da !important;
}

.patrner_forms .form-control.is-valid,
.was-validated .form-control:valid {
  background-image: none !important;
  border: 1px solid #ced4da !important;
}

.patrner_forms .form-control.is-valid:focus,
.was-validated .form-control:valid:focus {
  box-shadow: none !important;
}

.patrner_forms .form-control.is-invalid:focus,
.was-validated .form-control:invalid:focus {
  box-shadow: none !important;
}

.patrner_forms .custom-select {
  position: relative;
  font-family: Arial;
  background-image: none !important;
}

.patrner_forms .custom-select:before {
  content: "";
  position: absolute;
  background: url(../images/down_arrow.svg) !important;
  top: 8px;
  right: 5px;
  width: 20px;
  z-index: 9;
  height: 20px;
}

.patrner_forms .select-items:before {
  content: "";
  position: absolute;
  background: url(../images/down_arrow.svg) !important;
  transform: rotate(180deg);
  top: -29px;
  right: 4px;
  width: 20px;
  z-index: 99;
  height: 20px;
}

.patrner_forms .custom-select select {
  display: none;
}

.patrner_forms .patrner_forms .select-selected.select-arrow-active {
  padding: 0px;
}

.patrner_forms .select-items div,
.select-selected {
  color: #494949;
  cursor: pointer;
  user-select: none;
  padding: 10px;
}

.patrner_forms .select-selected {
  padding: 0px;
}

.patrner_forms .select-items {
  position: absolute;
  background-color: #fff;
  top: 100%;
  left: 0;
  right: 0;
  z-index: 99;
  box-shadow: rgba(0, 0, 0, 0.16) 0 0px 6px;
  font-family: "Roboto-Regular";
  font-size: 15px;
  border: 1px solid #ced4da;
}

.patrner_forms .select-hide {
  display: none;
}

.patrner_forms .select-items div:hover,
.same-as-selected {
  background-color: rgba(0, 0, 0, 0.1);
}

.become_our_partner_img {
  width: 100%;
  margin: 0 auto;
  display: block;
  text-align: center;
}

.become_our_partner_img img {
  width: 50%;
  float: left;
}

/* freq_ask_questions Start */
.freq_ask_questions {
  padding: 40px 0px;
  background: #fff;
}

.freq_ask_questions h2 {
  font-size: 40px;
  color: #333333;
  margin-bottom: 10px;
  font-family: "Roboto-Bold";
  text-align: center;
  width: 100%;
  float: left;
}

.freq_ask_questions h6 {
  width: 100%;
  float: left;
  text-align: center;
  font-size: 18px;
  color: #333333;
  font-family: "Roboto-Regular";
  margin-bottom: 40px;
}

.freq_ask_questions h4 {
  font-size: 22px;
  text-align: left;
  color: #333333;
  margin-bottom: 20px;
  font-family: "Roboto-Bold";
  width: 100%;
  float: left;
}

.freq_ask_questions p {
  font-size: 18px;
  text-align: left;
  color: #485a63;
  margin-bottom: 20px;
  font-family: "Roboto-Regular";
  width: 100%;
  float: left;
}

.freq_ask_questions p span {
  color: #2468f6;
  cursor: pointer;
}

.freq_ask_questions p span a {
  text-decoration: none !important;
  color: #2468f6 !important;
}

.freq_ask_questions p span a:hover {
  text-decoration: none;
  color: #2468f6;
}

.freq_ask_questions p span a:visited {
  text-decoration: none;
  color: #2468f6;
}

/* mobile responsive */
@media only screen and (max-width: 768px) {
  .become_our_partner h2 {
    text-align: center;
  }

  .become_our_partner p {
    text-align: center;
  }

  .become_our_partner .start-free-trail {
    text-align: center;
    margin-bottom: 40px;
  }

  .suprcharging_benefits_start_left img {
    position: relative;
    top: 75px;
  }
}

@media only screen and (max-width: 767px) {
  .partner_step h4 {
    position: inherit;
    margin-top: 0px;
    color: #333333;
    font-size: 30px;
    margin-bottom: 25px;
    font-family: "Gordita-Regular";
    line-height: 1.3;
    text-align: center;
    width: 100%;
  }

  .suprcharging_benefits_start_left img {
    display: none;
  }
}

/*** 

====================================================================
	Careers new page design  
====================================================================

***/
.container_trial .careers_head .main-slider-left h1 {
  font-size: 54px;
}

.cogni_fav_apps.career_skit .cogni_fav_apps_header p {
  text-align: center;
}

.cogni_fav_apps.career_skit {
  margin-bottom: 0px;
}

.mob_res_accordian.careers_accordian {
  display: block;
}

.careers_card {
  padding-bottom: 40px;
}

.container_trial .careers_card p {
  line-height: inherit;
  font-size: 16px;
  text-align: center;
}

.careers_head img {
  padding: 4em 0;
}

.careers_card h4 {
  color: #263238;
  font-size: 40px;
  font-family: "Gordita-Bold";
  text-align: center;
  margin-bottom: 20px;
}

.careers_accordian h4 {
  color: #263238;
  font-size: 24px;
  font-family: "Gordita-Bold";
  text-align: left;
  margin-bottom: 20px;
  width: 50%;
  float: left;
}

.careers_accordian .start-free-trail {
  float: right;
  width: 50%;
  text-align: right;
  margin-bottom: 30px;
}

.careers_accordian .card-header {
  padding: 5px;
}

.careers_accordian .btn-link:before {
  content: "\2212";
  position: absolute;
  top: -8px;
  right: 0;
  float: right;
  font-size: 35px;
  margin-right: 30px;
  margin-top: 10px;
  color: #ff9f02;
}

.careers_accordian .collapsed:after {
  content: "\2b";
  position: absolute;
  top: -8px;
  right: 0;
  float: right;
  font-size: 35px;
  align-items: center;
  justify-content: center;
  margin-right: 30px;
  margin-top: 10px;
  color: #999;
}

.careers_accordian h3 {
  text-align: left;
}

.careers_accordian ul {
  padding-left: 20px;
}

.careers_accordian ul li {
  list-style-type: disc;
  font-family: "Gordita-Regular";
  font-size: 15px;
  line-height: 25px;
  margin-bottom: 5px;
}

.careers_accordian .collapse {
  height: 280px;
  overflow-y: scroll;
}

.careers_head {
  padding-bottom: 80px;
  width: 100%;
  float: left;
}

@media only screen and (max-width: 1100px) {
  .careers_head .main-slider-right img {
    width: 100%;
  }

  .careers_head .main-slider-left p {
    font-size: 17px;
    margin-bottom: 25px;
    text-align: left;
    line-height: 30px;
    padding: 0px 5rem !important;
  }
}

@media only screen and (max-width: 768px) {
  .careers_accordian {
    padding: 0px 15px;
  }

  .careers_card {
    padding-top: 40px;
  }

  .careers_head .main-slider-right img {
    width: 70%;
  }
}

@media only screen and (max-width: 700px) {
  .careers_head .main-slider-left p {
    font-size: 17px;
    margin-top: 25px;
    text-align: left;
    line-height: 30px;
    padding: 0rem !important;
  }

  .careers_head img {
    padding: 0;
  }
}

@media only screen and (max-width: 600px) {
  .container_trial .careers_head h1 {
    font-size: 30px;
    text-align: left;
    margin-bottom: 0px;
  }

  .careers_head .main-slider-left p {
    font-size: 17px;
    margin-top: 25px;
    text-align: left;
    line-height: 30px;
    padding: 0rem !important;
  }

  .careers_head img {
    padding: 0;
  }

  .careers_accordian h4 {
    width: 100%;
    text-align: center;
  }

  .careers_accordian .start-free-trail {
    text-align: center;
    width: 100%;
    margin-top: 10px;
    margin-bottom: 40px;
  }
}

/* partner program forms start */
#_form_6_ {
  font-size: 14px;
  line-height: 1.6;
  margin: 0;
}

#_form_6_ * {
  outline: 0;
}

._form_hide {
  display: none;
  visibility: hidden;
}

._form_show {
  display: block;
  visibility: visible;
}

#_form_6_._form-top {
  top: 0;
}

#_form_6_._form-bottom {
  bottom: 0;
}

#_form_6_._form-left {
  left: 0;
}

#_form_6_._form-right {
  right: 0;
}

#_form_6_ input[type="text"],
#_form_6_ input[type="date"],
#_form_6_ textarea {
  padding: 6px;
  height: auto;
  border: #979797 1px solid;
  border-radius: 0px;
  color: #000 !important;
  font-size: 13px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

#_form_6_ textarea {
  resize: none;
}

#_form_6_ ._submit {
  -webkit-appearance: none;
  cursor: pointer !important;
  font-size: 16px;
  text-align: center;
  background: #2468f6 !important;
  border: 0 !important;
  -moz-border-radius: 0px !important;
  -webkit-border-radius: 0px !important;
  border-radius: 30px !important;
  color: #fff !important;
  padding: 10px !important;
  text-transform: uppercase;
}

#_form_6_submit:hover {
  color: #fff !important;
  background-color: #1757dc !important;
  cursor: pointer !important;
}

#_form_6_ ._close-icon {
  cursor: pointer;
  background-image: url("https://d226aj4ao1t61q.cloudfront.net/esfkyjh1u_forms-close-dark.png");
  background-repeat: no-repeat;
  background-size: 14.2px 14.2px;
  position: absolute;
  display: block;
  top: 11px;
  right: 9px;
  overflow: hidden;
  width: 16.2px;
  height: 16.2px;
}

#_form_6_ ._close-icon:before {
  position: relative;
}

#_form_6_ ._form-body {
  margin-bottom: 30px;
}

#_form_6_ ._form-image-left {
  width: 150px;
  float: left;
}

#_form_6_ ._form-content-right {
  margin-left: 164px;
}

#_form_6_ ._form-branding {
  color: #fff;
  font-size: 10px;
  clear: both;
  text-align: left;
  margin-top: 30px;
  font-weight: 100;
}

#_form_6_ ._form-branding ._logo {
  display: block;
  width: 130px;
  height: 14px;
  margin-top: 6px;
  background-image: url("https://d226aj4ao1t61q.cloudfront.net/hh9ujqgv5_aclogo_li.png");
  background-size: 130px auto;
  background-repeat: no-repeat;
}

#_form_6_ ._form-label,
#_form_6_ ._form_element ._form-label {
  font-weight: bold;
  margin-bottom: 5px;
  display: block;
}

#_form_6_._dark ._form-branding {
  color: #333;
}

#_form_6_._dark ._form-branding ._logo {
  background-image: url("https://d226aj4ao1t61q.cloudfront.net/jftq2c8s_aclogo_dk.png");
}

#_form_6_ ._form_element {
  position: relative;
  margin-bottom: 10px;
  font-size: 0;
  max-width: 100%;
}

#_form_6_ ._form_element * {
  font-size: 14px;
  margin-bottom: 0px;
  font-family: "Gordita-Regular";
}

#_form_6_ ._form_element._clear {
  clear: both;
  width: 100%;
  float: none;
}

#_form_6_ ._form_element._clear:after {
  clear: left;
}

#_form_6_ ._form_element input[type="text"],
#_form_6_ ._form_element input[type="date"],
#_form_6_ ._form_element select,
#_form_6_ ._form_element textarea:not(.g-recaptcha-response) {
  display: block;
  width: 100%;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  height: 34px;
  background: #fff;
}

#_form_6_ ._field-wrapper {
  position: relative;
}

#_form_6_ ._inline-style {
  float: left;
}

#_form_6_ ._inline-style input[type="text"] {
  width: 150px;
}

#_form_6_ ._inline-style:not(._clear) + ._inline-style:not(._clear) {
  margin-left: 20px;
}

#_form_6_ ._form_element img._form-image {
  max-width: 100%;
}

#_form_6_ ._clear-element {
  clear: left;
}

#_form_6_ ._full_width {
  width: 45%;
  float: left;
  margin: 5px 10px;
}

#_form_6_ ._full_width:nth-of-type(odd) {
  margin-left: 0px;
}

#_form_6_ ._form_full_field {
  display: block;
  width: 100%;
  margin-bottom: 10px;
}

#_form_6_ input[type="text"]._has_error,
#_form_6_ textarea._has_error {
  border: #f37c7b 1px solid;
}

#_form_6_ input[type="checkbox"]._has_error {
  outline: #f37c7b 1px solid;
}

#_form_6_ ._error {
  display: block;
  position: absolute;
  font-size: 13px;
  z-index: 10000001;
}

#_form_6_ ._error._above {
  padding-bottom: 4px;
  bottom: 39px;
  right: 0;
}

#_form_6_ ._error._below {
  padding-top: 4px;
  top: 100%;
  right: 0;
}

#_form_6_ ._error._above ._error-arrow {
  bottom: 0;
  right: 15px;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid #f37c7b;
}

#_form_6_ ._error._below ._error-arrow {
  top: 0;
  right: 15px;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-bottom: 5px solid #f37c7b;
}

#_form_6_ ._error-inner {
  padding: 8px 12px;
  background-color: #f37c7b;
  font-size: 13px;
  color: #fff;
  text-align: center;
  text-decoration: none;
  -webkit-border-radius: 0px;
  -moz-border-radius: 0px;
  border-radius: 0px;
}

#_form_6_ ._error-inner._form_error {
  margin-bottom: 5px;
  text-align: left;
}

#_form_6_ ._button-wrapper ._error-inner._form_error {
  position: static;
}

#_form_6_ ._error-inner._no_arrow {
  margin-bottom: 10px;
}

#_form_6_ ._error-arrow {
  position: absolute;
  width: 0;
  height: 0;
}

#_form_6_ ._error-html {
  margin-bottom: 10px;
}

.pika-single {
  z-index: 10000001 !important;
}

@media all and (min-width: 320px) and (max-width: 667px) {
  ::-webkit-scrollbar {
    display: none;
  }

  .cards_solutions_x_scroll::-webkit-scrollbar {
    display: block;
  }

  #_form_6_ {
    margin: 0;
    width: 100%;
    min-width: 100%;
    max-width: 100%;
    box-sizing: border-box;
  }

  #_form_6_ * {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    font-size: 1em;
  }

  #_form_6_ ._form-content {
    margin: 0;
    width: 100%;
  }

  #_form_6_ ._form-inner {
    display: block;
    min-width: 100%;
  }

  #_form_6_ ._form-title,
  #_form_6_ ._inline-style {
    margin-top: 0;
    margin-right: 0;
    margin-left: 0;
  }

  #_form_6_ ._form-title {
    font-size: 1.2em;
  }

  #_form_6_ ._form_element {
    margin: 0 0 20px;
    padding: 0;
    width: 100%;
  }

  #_form_6_ ._form-element,
  #_form_6_ ._inline-style,
  #_form_6_ input[type="text"],
  #_form_6_ label,
  #_form_6_ p,
  #_form_6_ textarea:not(.g-recaptcha-response) {
    float: none;
    display: block;
    width: 100%;
  }

  #_form_6_ ._row._checkbox-radio label {
    display: inline;
  }

  #_form_6_ ._row,
  #_form_6_ p,
  #_form_6_ label {
    margin-bottom: 0.7em;
    width: 100%;
  }

  #_form_6_ ._row input[type="checkbox"],
  #_form_6_ ._row input[type="radio"] {
    margin: 0 !important;
    vertical-align: middle !important;
  }

  #_form_6_ ._row input[type="checkbox"] + span label {
    display: inline;
  }

  #_form_6_ ._row span label {
    margin: 0 !important;
    width: initial !important;
    vertical-align: middle !important;
  }

  #_form_6_ ._form-image {
    max-width: 100%;
    height: auto !important;
  }

  #_form_6_ input[type="text"] {
    padding-left: 10px;
    padding-right: 10px;
    font-size: 16px;
    line-height: 1.3em;
    -webkit-appearance: none;
  }

  #_form_6_ input[type="radio"],
  #_form_6_ input[type="checkbox"] {
    display: inline-block;
    width: 1.3em;
    height: 1.3em;
    font-size: 1em;
    margin: 0 0.3em 0 0;
    vertical-align: baseline;
  }

  #_form_6_ button[type="submit"] {
    padding: 20px;
    font-size: 15px;
  }

  #_form_6_ ._inline-style {
    margin: 20px 0 0 !important;
  }
}

#_form_6_ {
  position: relative;
  text-align: left;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  *zoom: 1;
  background: #fff !important;
  border: 0px solid #b0b0b0 !important;
  width: 100%;
  -moz-border-radius: 0px !important;
  -webkit-border-radius: 0px !important;
  border-radius: 0px !important;
  color: #000 !important;
}

#_form_6_ ._form-title {
  font-size: 40px;
  text-align: left;
  color: #333333;
  margin-bottom: 0;
}

#_form_6_ ._form-title h2 {
  margin-bottom: 0;
}

#_form_6_:before,
#_form_6_:after {
  content: " ";
  display: table;
}

#_form_6_:after {
  clear: both;
}

#_form_6_._inline-style {
  width: auto;
  display: inline-block;
}

#_form_6_._inline-style input[type="text"],
#_form_6_._inline-style input[type="date"] {
  padding: 10px 12px;
}

#_form_6_._inline-style button._inline-style {
  position: relative;
  top: 27px;
}

#_form_6_._inline-style p {
  margin: 0;
}

#_form_6_._inline-style ._button-wrapper {
  position: relative;
  margin: 27px 12.5px 0 20px;
}

#_form_6_ ._form-thank-you {
  position: relative;
  left: 0;
  right: 0;
  text-align: center;
  font-size: 18px;
  background: #f4f4f4;
  height: 60px;
  align-items: center;
  justify-content: center;
  margin: 100px 0px;
  font-family: "Roboto-Medium";
  line-height: 1.54;
  letter-spacing: -0.2px;
  color: #485a63;
  padding-top: 15px;
}

@media all and (min-width: 320px) and (max-width: 667px) {
  #_form_6_._inline-form._inline-style ._inline-style._button-wrapper {
    margin-top: 20px !important;
    margin-left: 0 !important;
  }
}

/* partner progrm form end */

/* Assistant slider start */
.assistant_right_img img {
  padding: 3em 0;
}

.assistant_change_img {
  width: 80%;
  background: #fff;
  box-shadow: rgba(0, 0, 0, 0.16) 0 3px 6px;
  margin-bottom: 40px !important;
  margin: 0 auto;
  display: block;
}

.assistant_change_img img {
  width: 56%;
  float: left;
  height: 100%;
}

.assistant_change_img .dialog-editor-content {
  width: 40%;
  float: left;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 320px;
  padding: 20px 0px 20px 20px;
}

.assistant_change_img .dialog-editor-content p {
  padding: 0px;
  text-align: left;
  margin: 0px;
  font-size: 15px;
}

.dialog-editor-content a {
  text-align: left;
  text-decoration: none;
  width: 100%;
  float: left;
  margin-top: 10px;
  color: #2468f6;
  cursor: pointer;
}

.dialog-editor-content a:hover {
  text-decoration: none;
  color: #1757dc;
}

.assistant_change_img .carousel-indicators {
  bottom: -55px !important;
  z-index: 10;
}

.assistant_change_img .dialog-editor-content h5 {
  padding: 0px;
  text-align: left;
  width: 100%;
  float: left;
}

.carousel-indicators .active {
  background: #2468f6 !important;
  border: none;
}

.carousel-control-next-icon {
  background: url(../images/left_arrow.svg) no-repeat !important;
  transform: rotate(-90deg);
}

.carousel-control-prev-icon {
  background: url(../images/left_arrow.svg) no-repeat !important;
  transform: rotate(90deg);
}

.carousel-indicators li {
  width: 10px !important;
  height: 10px !important;
  border-radius: 50%;
  border: none !important;
  background: #2468f6 !important;
  opacity: 0.1;
}

.bg_img_assistant_top {
  background-size: cover;
  width: 100%;
  height: 100%;
  float: left;
  position: absolute;
  top: -91px;
  background: linear-gradient(
    to bottom,
    rgba(207, 240, 253, 1) 0%,
    rgba(207, 240, 253, 1) 19%,
    rgba(246, 246, 246, 1) 75%,
    rgba(255, 255, 255, 1) 100%
  );
}

.main-slider .container {
  position: relative;
}

.header-logo.mobile-logo {
  z-index: 0;
  height: 80px;
}

/* css fixed */
.wv-footer {
  width: 100%;
  float: left;
}

/* Slack Landing */

.automate-slack-integration {
  padding: 7em 0;
}

.cogni_works.cogni-slack-integration h1 {
  margin-bottom: 52px;
}

.automate-slack-integration h1 {
  font-family: "Roboto-Bold";
  font-size: 26px;
  color: #333333;
  letter-spacing: -1.5;
  margin-bottom: 15px;
}

.automate-slack-integration p {
  font-size: 17px;
  color: #485a63;
  font-family: "Roboto-Regular";
  line-height: 1.5;
  margin-bottom: 30px;
}

.slack-integration-right {
  padding: 6em 0 7em 0;
}

.slack-integration-right h1 {
  font-family: "Roboto-Bold";
  font-size: 26px;
  color: #333333;
  letter-spacing: -1.5;
  margin-bottom: 15px;
}

.slack-integration-right p {
  font-size: 17px;
  color: #485a63;
  font-family: "Roboto-Regular";
  line-height: 1.5;
  margin-bottom: 30px;
}

.slack-integration-chat {
  width: 100%;
  float: left;
  background: #d5e4f8;
  padding-top: 60px;
  padding-bottom: 30px;
  margin-top: 0px;
}

.int-slack-integration {
  padding: 2em 0;
}

.int-slack-integration h1 {
  font-family: "Roboto-Bold";
  font-size: 26px;
  color: #333333;
  letter-spacing: -1.5;
  margin-bottom: 15px;
}

.int-slack-integration p {
  font-size: 17px;
  color: #485a63;
  font-family: "Roboto-Regular";
  line-height: 1.5;
  margin-bottom: 30px;
}

.slack-super-charging.fresh-desk {
  padding-top: 0px;
}

.slack-super-charging {
  padding-top: 30px;
  margin: 0 auto;
}

.slack-super-charging ul {
  padding-left: 0;
}

.slack-super-charging.fresh-desk ul li {
  padding: 0 0.5em;
}

.slack-super-charging ul li {
  display: inline-block;
  padding: 0 2.5em;
  text-align: center;
}

.slack-super-charging p {
  color: #485a63;
  font-size: 14px;
  font-family: "Roboto-Regular";
  text-align: center;
  margin-bottom: 0px;
  padding: 1.5em 0rem;
  font-weight: 600;
  letter-spacing: 0.5px;
}

.slack-super-charging.fresh-desk img {
  width: 28px;
  height: 28px;
  margin-right: 7px;
}

.add-to-slack {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #555555;
  border-radius: 50px;
  cursor: pointer;
  padding: 0px !important;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}

.add-to-slack:hover {
  border: 1px solid #1757dc;
}

.slack-padding a:hover {
  text-decoration: none;
}

.how-to-deploy {
  color: #2468f6 !important;
  text-decoration: underline;
  cursor: pointer;
  margin-top: 30px;
}

li.slack-padding {
  padding: 0 !important;
}

.slack-int-left {
  padding: 3em 0;
}

.slack-integration-workplace {
  margin-bottom: 0px;
  width: 100%;
  float: left;
  background: #ffffff;
  padding-top: 60px;
  padding-bottom: 80px;
  margin-top: 0px;
}

.automate_assistant_left_img img {
  padding: 0em 0em 0em 3em;
}

@media (max-width: 480px) and (min-width: 320px) {
  .cogni-slack-integration h1 {
    font-size: 26px !important;
  }

  .automate_assistant_left_img img {
    padding: 0em !important;
    margin-bottom: 30px;
  }

  .automate-slack-integration {
    padding: 0em 0;
  }

  .slack-integration-right {
    padding: 0em 10px 0em 10px;
  }

  .automate-slack-integration h1 {
    font-size: 24px;
  }

  .slack-integration-right h1 {
    font-size: 24px;
  }

  .slack-integration-chat {
    padding-top: 0px;
    padding-bottom: 0px;
  }

  .super-charging ul li {
    padding: 0 0em !important;
    width: 45%;
  }

  .super-charging .slack-padding {
    padding: 0 0em !important;
    width: 180px;
  }

  .slack-super-charging p {
    text-align: left;
  }

  .slack-int-workativ {
    padding: 0;
  }

  .chat_password {
    margin-top: 0;
  }

  .slack-int-left {
    padding: 1em 0;
  }
}

@media only screen and (max-width: 1024px) and (min-width: 769px) {
  .automate-slack-integration {
    padding: 2em 0;
  }

  .automate_assistant_left_img img {
    padding: 0em 0em 0em 0em;
  }

  .slack-integration-right {
    padding: 0em 0 1.5em 0;
  }

  .wv-banner.wv-slack-integration .container::after {
    right: 7% !important;
    bottom: 22% !important;
  }

  .wv-banner.wv-teams-integration .container::after {
    right: 7% !important;
    bottom: 22% !important;
  }

  .wv-slack-integration .__content {
    width: 60%;
    padding: 6.5em 2em;
  }
}

@media screen and (max-width: 740px) {
  .wv-banner.wv-slack-integration .container {
    height: 460px;
    position: relative;
  }

  .wv-banner.wv-teams-integration .container {
    height: 460px;
    position: relative;
  }

  .wv-first-section .__content,
  .wv-banner.wv-slack-integration .__content {
    width: 100%;
    padding: 35px 35px;
    top: 0;
  }

  .wv-first-section .__content,
  .wv-banner.wv-teams-integration .__content {
    width: 100%;
    padding: 35px 35px;
    top: 0;
  }

  .automate-slack-integration {
    padding: 2em 0;
  }

  .slack-integration-right {
    padding: 2em 0 0em 0;
  }

  .slack-integration-chat {
    padding-top: 0px;
    padding-bottom: 0px;
  }

  .chat_password {
    margin-top: 0;
  }

  .slack-int-left {
    padding: 1em 0;
  }
}

.wv-banner.wv-slack-integration .container::after {
  background-image: url("../images/slack_header.png");
}

.wv-banner.wv-slack-integration {
  background-image: url("../images/slack-integration.png");
  background-size: cover;
}

.wv-banner.wv-teams-integration {
  background-image: url("../images/teams_banner.png");
  background-size: cover;
}

.slack-padding {
  width: 180px;
}

.wv-teams-integration {
  background-image: url("../images/teams_banner.png");
  background-size: cover;
}

.wv-banner.wv-teams-integration .container::after {
  background-image: url("../images/teams_header.png");
}

.wv-banner.wv-teams-integration .container::after {
  content: "";
  width: 305px;
  height: 304px;
  position: absolute;
  right: 10%;
  bottom: 13%;
  background-size: contain;
  background-repeat: no-repeat;
}

/* Solutions Page Start */

/* scrollbar */
.cards_solutions_x_scroll::-webkit-scrollbar {
  width: 3px;
}

.cards_solutions_x_scroll::-webkit-scrollbar:horizontal {
  height: 3px;
}

.cards_solutions_x_scroll::-webkit-scrollbar-track {
  background-color: rgba(204, 204, 204, 0.3);
}

.cards_solutions_x_scroll::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: rgba(204, 204, 204, 0.5);
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
}

/* scrollbar */

.solutions_header {
  width: 100%;
  float: left;
  padding-top: 0px;
  padding-bottom: 0px;
  padding-left: 0px;
  padding-right: 15px;
  overflow: hidden;
}

.solutions_left_img {
  width: 100%;
  height: 100%;
}

.solutions_left_img img {
  position: absolute;
  width: 120%;
  left: -130px;
}

.solutions_right_img {
  width: 100%;
  height: 100%;
}

.solutions_right_img img {
  position: absolute;
  width: 120%;
  left: 50px;
}

.solutions_center {
  width: 100%;
  float: left;
}

.solutions_center .start-free-trail {
  width: 100%;
  margin: 20px 0px;
  float: left;
}

.solutions_center h1 {
  font-size: 46px;
  color: #263238;
  font-family: "Roboto-Bold";
  text-align: left;
  margin-bottom: 20px;
  width: 100%;
  float: left;
}

.solutions_center p {
  font-size: 22px;
  color: #485a63;
  font-family: "Roboto-Regular";
  line-height: 1.5;
  margin-bottom: 30px;

  text-align: left;
  width: 100%;
  float: left;
}

.solutions_left_img {
  background: url("../images/solutions/hero-graphic.png");
  background-repeat: no-repeat;
  background-position: center left;
  height: 100%;
  background-position-x: -230px;
  position: absolute;
  top: 0;
  width: 120%;
  z-index: -1;
  background-size: contain;
}

.solutions_right_img {
  background: url("../images/solutions/hero-graphic.png");
  background-repeat: no-repeat;
  background-position: center left;
  height: 100%;
  background-position-x: 60px;
  position: absolute;
  top: 0;
  width: 130%;
  z-index: -1;
  background-size: contain;
}

.solutions_header .w-30 {
  flex: 0 0 30%;
  max-width: 30%;
}

.solutions_header .w-40 {
  flex: 0 0 40%;
  max-width: 40%;
  position: relative;
  top: 20px;
}

.solution_service {
  width: 100%;
  float: left;
  padding: 40px 0px 40px 0px;
}

.solution_service_card {
  width: 100%;
  float: left;
  border: 1px solid #f4f9fd;
  padding: 60px 60px 0px 60px;
  background: #f4f9fd;
  text-align: center;
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
}

.solution_service_card h5 {
  font-size: 30px;
  color: #263238;
  font-family: "Roboto-Bold";
  text-align: left;
  margin-bottom: 20px;
  width: 100%;
  float: left;
  flex: 1 1 auto;
  display: flex;
}

.solution_service_card p {
  font-size: 18px;
  color: #263238;
  font-family: "Roboto-Regular";
  text-align: left;
  margin-bottom: 20px;
  width: 100%;
  float: left;
}

.solution_service_card img {
  position: relative;
  top: 18px;
}

.conversational_facelift {
  width: 100%;
  float: left;
  padding: 40px 0px;
}

.conversational_facelift_header {
  width: 100%;
  float: left;
  padding: 0px 30px;
}

.conversational_facelift_header {
  width: 100%;
  float: left;
  padding: 60px 35px !important;
  background: #f4f9fd;
}

.conversational_facelift_card img {
  width: 54px;
  height: 54px;
  margin-bottom: 15px;
}

.conversational_facelift_header h4 {
  font-size: 30px;
  color: #263238;
  font-family: "Roboto-Bold";
  text-align: center;
  margin-bottom: 40px;
}

.conversational_facelift_card p {
  margin-bottom: 0px;
  font-size: 18px;
  color: #263238;
  font-family: "Roboto-Regular";
  text-align: left;
}

.conversational_facelift_card {
  width: 100%;
  float: left;
  text-align: left;
}

.pad_25 {
  padding: 0px 25px !important;
}

.card_office.solutions_card {
  justify-content: left;
  background: #fff;
  box-shadow: none;
  padding: 0px 25px;
  margin-bottom: 2px;
  margin-top: 12px;
  width: 100%;
}

.solutions_card_desk .card_office.solutions_card h5 {
  margin-top: 15px !important;
}

.card_office.solutions_card img {
  width: 64px !important;
}

.card_head.solutions_card {
  background: #fff;
  padding-bottom: 20px;
  padding-top: 0px;
}

.solutions_card_desk {
  border-radius: 0px;
}

.card_head.solutions_card p {
  margin-bottom: 10px;
  margin-top: 10px;
  min-height: 68px;
}

.know-how-assistant.solutions_assistant {
  background: #fff;
  margin-top: 0px;
  padding-bottom: 40px;
}

.solutions_integrations_card h4 {
  font-size: 30px;
  color: #263238;
  font-family: "Roboto-Bold";
  text-align: center;
  margin-bottom: 40px;
}

/* mobile x-scroll */
.conversational_facelift_card_scroll {
  overflow-x: scroll;
  max-width: 60%;
  flex: 0 0 60%;
  margin-right: 10px;
  padding-right: 15px;
  display: flex;
  flex-wrap: wrap;
  font-size: 14px;
}

.conversational_facelift_card_scroll img {
  width: 4em;
  height: 4em;
  margin-bottom: 0.4rem;
}

.cards_solutions_x_scroll {
  align-items: center;
  flex-direction: row;
  width: 100%;
  float: left;
  display: flex;
  overflow-x: auto;
}

.cards_solutions_x_scroll::-webkit-scrollbar {
  -webkit-appearance: block !important;
}

.cards_solutions_x_scroll::-webkit-scrollbar-thumb {
  border-radius: 8px;
  border: 2px solid #494949;
  background-color: #494949;
}

.x_scroll_mobile {
  display: none;
}

.know-how-assistant.solutions_assistant p {
  margin-right: 12rem;
}

._field-wrapper select {
  border-radius: 0px !important;
  -webkit-appearance: inherit;
  padding: 0px 10px;
}

/* home page redesign */
.assistant_change_img {
  display: none;
}

.cogni_fav_apps.cc_feature_apps {
  background: #f5f6f8;
  width: 100%;
  float: left;
  margin-bottom: 80px;
  padding-top: 80px;
}

.cc_feature_apps_header h3 {
  text-align: center;
  font-family: "Roboto-Medium";
  font-size: 38px;
  color: #000;
  margin-bottom: 70px;
  width: 100%;
  float: left;
  padding: 0px 20%;
}

.cc_feature_apps .cogni_fav_apps_color {
  width: 100%;
  float: left;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.cc_feature_apps .card_office {
  background: #fff;
  width: 72px;
  height: 72px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 15px;
  box-shadow: rgba(0, 0, 0, 0.16) 0 3px 6px;
  float: left;
  margin-right: 0px;
}

.cc_feature_apps .card_office img {
  width: 36px;
  height: 36px;
}

.cc_feature_apps .card_office.ibm_img img {
  width: 36px;
  height: 30px;
}

.cc_feature_apps .card_office_cc h6 {
  text-align: left;
  font-family: "Roboto-Medium";
  font-size: 14px;
  letter-spacing: 0;
  color: #354250;
  width: 100%;
  float: left;
  margin-top: 20px;
  margin-bottom: 0px;
}

.card_office_cc {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.cc_feature_apps h5 {
  width: 100%;
  float: left;
}

.cc_feature_apps h5 a {
  font-family: "Roboto-Medium";
  font-size: 15px;
  width: 100%;
  float: left;
  margin-top: 60px;
  margin-bottom: 0px;
  text-align: center;
  letter-spacing: 0.15px;
  color: #2e6dfa;
  cursor: pointer;
}

.cc_feature_apps h5:hover a {
  text-decoration: none;
  color: #2e6dfa;
  cursor: pointer;
}

.mob_res_cards {
  width: 100%;
  float: left;
}

.mob_res_cards .cognii_work_content {
  margin-bottom: 0px;
  padding-top: 80px;
}

.apps_integrations {
  width: 100%;
  float: left;
}

.apps_integrations .cogni_fav_apps.cc_feature_apps {
  margin-bottom: 0px;
  padding-bottom: 0px;
  background: #fff;
}

.mob_res_cards .cognii_work_content .benefits-workplace-content {
  background: #f8f8f8;
  box-shadow: none;
}

.mob_res_cards .cognii_work_content .cogni_workspace_content_before:before {
  display: none;
}

.mob_res_cards .cognii_work_content {
  background: #f8f8f8;
}

.mob_res_cards .cognii_work_content .benefits-workplace-content img {
  width: 100%;
  float: left;
}

.mob_res_cards .cognii_work_content .cogni_workspace_content p {
  text-align: left;
  min-height: 75px;
  width: 100%;
  float: left;
}

.mob_res_cards .cognii_work_content .cogni_workspace_content a {
  text-align: left;
  font-family: "Roboto-Medium";
  font-size: 15px;
  color: #2e6dfa;
  padding-bottom: 24px;
  width: auto;
  float: left;
  margin-top: 15px;
  text-decoration: none;
}

.mob_res_cards .cognii_work_content .cogni_works p {
  margin-bottom: 0px;
}

.cogni_fav_apps.cc_feature_apps .row {
  width: 100%;
  float: left;
}

.mob_res_cards .cogni_workspace_content {
  box-shadow: none;
  padding: 0px;
  border-radius: 0px;
  text-align: center;
  margin-top: 0px;
  margin-bottom: 0px;
}

.mob_res_cards .benefits-workplace-content h3 {
  text-align: left;
  width: 100%;
  float: left;
}

.mob_res_cards .cognii_work_content .cogni_works h1 {
  text-align: left;
}

.video_prompt {
  width: 90%;
  position: relative;
  background:#FFF5F5 0% 0% no-repeat padding-box;
  background-size: cover;
  height: 500px;
  display: flex;
  align-items: center;
  background-position: 95% 50%;
  margin-bottom: 0px;
  box-shadow: 0px 10px 20px #00000040;
  border-radius: 20px;
  margin:auto;
}
.video_prompt2 {
  width: 100%;
  float: left;
  position: relative;
  background-size: contain;
  height: 550px;
  display: flex;
  align-items: center;
  margin-bottom: 0px;
  background-repeat: no-repeat;
  background-position: 50% 50%;
}

.home_bg_header .p-r-35 {
  padding-right: 35px;
  display: flex;
  align-items: center;
}

.home_bg_header.sticky {
  background: #fff;
}

.assistant_slider.home_bg_header {
  padding-top: 20px;
  padding-bottom: 60px;
}

.assistant_slider.home_bg_header .main-slider-left {
  padding-top: 0px;
  padding-bottom: 0px;
}

.assistant_slider.home_bg_header img {
  padding-top: 0px;
  padding-bottom: 0px;
  filter: drop-shadow(0px 3px 5px rgb(104, 103, 103, 0.1));
}

.bg_slider_home .main-slider-right img {
  height: 100%;
}

.video_show {
  width: 100%;
  float: left;
  display: flex;
  align-items: center;
  justify-content: center;
}

.video_show .nav-item.main-download-broucher {
  position: relative;
  display: flex;
  align-items: center;
}

.video_append {
  display: flex;
  align-items: center;
}

.video_append a {
  display: flex;
  align-items: center;
  justify-content: center;
}

.video_show .svg_bg svg {
  width: 30px;
  height: 30px;
  fill: #E56B6B;
  margin-right: 15px;
}
.video_show2 {
  width: 100%;
  float: left;
  display: flex;
  align-items: center;
  justify-content: center;
}
.video_show2 .svg_bg svg {
  width: 50px;
  height: 50px;
  fill: #E56B6B;
  margin-right: 15px;
}
.video_show2 .nav-item.main-download-broucher {
  position: relative;
  display: flex;
  align-items: center;
}

.watch_video {
  width: auto;
  float: left;
  font-size: 26px;
  color: #416183;
  text-decoration: none;
  /* padding-bottom: 3px; */
}

.helpdesk_solutions {
  width: 100%;
  float: left;
  background: #fff;
  padding-top: 80px;
}

.helpdesk_solutions .suprcharging_benefits {
  background: #fff;
  padding: 0px;
}

.helpdesk_solutions .main-slider-left {
  padding-top: 0px;
  width: 100%;
  float: left;
  padding-bottom: 20px;
}

.helpdesk_solutions .suprcharging_benefits .cogni_workspace_content {
  box-shadow: none;
  padding: 0px;
  text-align: left;
}

.apps_integrations .cogni_fav_apps_color .card_office {
  margin: 0px;
  margin-bottom: 10px;
}

.helpdesk_solutions .suprcharging_benefits .cogni_workspace_content p {
  min-height: 0px;
  margin-bottom: 10px;
  font-size: 18px;
}

.helpdesk_solutions .cogni_workspace_content h3 {
  margin-bottom: 10px;
  font-size: 22px;
}

.helpdesk_solutions .suprcharging_benefits .cogni_workspace_content a {
  text-align: left;
  font-family: "Roboto-Medium";
  font-size: 15px;
  color: #2e6dfa;
  padding-bottom: 0px;
  width: auto;
  float: left;
  margin-top: 20px;
  text-decoration: none;
}

.helpdesk_solutions .suprcharging_benefits .cogni_workspace_content img {
  width: 64px;
  height: 64px;
}

.apps_integrations .cc_feature_apps .card_office_cc h6 {
  text-align: center;
}

.apps_integrations .cc_feature_apps .card_office.ibm_img img {
  width: 36px;
  height: 36px;
}

.know-how-assistant-image.mobile_change_footer_img {
  display: none;
}

.know-how-assistant-image {
  display: block;
}

.footer_chat .know-how-assistant {
  background: #fff;
}

.bg_slider_home {
  background: #EDF1FF;
  padding: 80px 0px;
  width: 100%;
  float: left;
}

.bg_slider_home .assistant_right_img img {
  padding: 0px;
}

.bg_slider_home .main-slider-left {
  padding: 0px;
}

.cognii_work_content.home_works_content {
  margin-bottom: 0px;
  background: #fff;
  padding-bottom: 0px;
}

.marketplace_integrations_icons {
  width: 100%;
  float: left;
  padding: 80px 60px;
  background: #f8f8f8;
}

.home_works_content .cognii_work_content .benefits-workplace-content img {
  margin-top: 10px;
}

.home_works_content h3 span {
  margin-right: 5px;
}

.apps_integrations .cc_feature_apps_header h3 {
  padding: 0px 12%;
}

.video_prompt .main-slider-left h3 {
  padding: 0px;
  position: absolute;
  top: 120px;
  width: 100%;
  text-align: center;
  color: #000;
  font-size: 40px;
  font-family:"Gordita-Medium";
}

.video_prompt .comapny_name {
  position: absolute;
  bottom: 40px;
  right: 90px;
  font-size: 28px;
  font-family: "Gordita-Medium";
  color: #416183;
}

.video_prompt .main-slider-left {
  padding: 0px;
}

.video_prompt_section {
  width: 100%;
  float: left;
  margin-top: 0px;
  margin-bottom: 50px;
}

.home_works_content .assiatant_learn_more {
  margin-bottom: 20px;
}

.align_center_content {
  display: flex;
  align-items: center;
  width: 100%;
  float: left;
}

.bg_slider_home.m_b_80 {
  margin-bottom: 80px;
  padding: 80px 0px;
  background: #e7f2fd;
}

.new_design_landing_footer {
  background: #fff;
}

.pad_right_20 {
  padding-right: 20%;
}

.card_head span.wv-more-txt {
  width: 100%;
  float: left;
  margin-top: 5px;
}

.integrations_card_desk .card_head p {
  min-height: 65px;
}

.integration_boxes {
  width: 100%;
  float: left;
  display: flex;
  flex-wrap: wrap;
}

.search_marketpalce {
  width: 100%;
  float: left;
  margin: 0px 15px 40px;
  position: relative;
  margin-left:0px;
}

.search_marketpalce .search_click {
  width: 100%;
  float: left;
  border: 1px solid #999;
  background: #fff;
  outline: 0;
  padding: 7px 15px;
  padding-left: 40px;
  border-radius: 6px;
}

.search_marketpalce .search_icon {
  position: absolute;
  left: 5px;
  width: 35px;
  height: 35px;
  background: #fff;
  top: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Featured Content Slider Start */
.slick-carousel {
  width: 100%;
  margin: 0px auto;
}

.slick-carousel {
  margin: 0px;
}

.featured_content .slick-slide {
  padding-right: 40px;
}

.featured_content .slick-slide:last-child {
  padding-right: 0px;
}

.featured_content .slick-slide:first-child {
  padding-left: 0px;
}

.featured_content {
  width: 100%;
  float: left;
  padding-top: 0px;
  margin-bottom: 80px;
  background: #f7f7f7;
  padding: 60px 0px;
  overflow-x: hidden;
}

.featured_content .cogni_workspace_content {
  box-shadow: rgba(0, 0, 0, 0.16) 0 0px 6px;
  margin-top: 50px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  cursor: move;
  background: #fff;
}

.featured_content .benefits-workplace-content img {
  width: 64px;
  height: 64px;
  margin-bottom: 20px;
}

.featured_content .cogni_workspace_content h3,
a {
  text-align: left;
}

.featured_content .cogni_workspace_content p {
  margin-bottom: 0px;
  font-size: 15px;
  line-height: 25px;
  min-height: 50px;
  text-align: left;
}

.featured_content .drag {
  position: absolute;
  right: 10px;
  width: 60px;
  height: 60px;
  border: 1px solid #ccc;
  border-radius: 50%;
  display: none;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  background: #fff;
}

.featured_content .slick-list {
  overflow: unset;
}

.featured_content .container {
  position: relative;
}

.overflow-x-hidden {
  overflow-x: hidden;
}

.featured_content h3 {
  color: #263238;
  font-size: 40px;
  font-family: "Roboto-Bold";
  margin-bottom: 0px;
  text-align: left;
}

.bg_card_1 {
  /* background: rgb(51, 144, 82) 0% 0% no-repeat padding-box; */
background-color: red;
  border-radius: 20px;
}

.helpdesk_solutions .bg_card_1 .cogni_workspace_content {
  background: inherit;
  margin: 0px;
  padding: 40px;
  width: 100%;
  float: left;
}

.bg_card_2 {
  background: #FBF9F4 0% 0% no-repeat padding-box;
  border-radius: 20px;
}

.helpdesk_solutions .bg_card_2 .cogni_workspace_content {
  background: inherit;
  margin-top: 10px;
  padding: 40px;
  width: 100%;
  float: left;
  margin-bottom: 10px;
}

.home_bg_card_1{
background: #F1FAF4 0% 0% no-repeat padding-box;
border-radius: 20px;
}
.home_bg_card_2{
  background: #FBF9F4 0% 0% no-repeat padding-box;
border-radius: 20px;
}
.bg_card_3 {
  background:  #F1FAF4 0% 0% no-repeat padding-box;
  border-radius: 20px;
}

.bg_card_4 {
  background: #FBF9F4 0% 0% no-repeat padding-box;
  border-radius: 20px;
}

.helpdesk_solutions .bg_card_3 .cogni_workspace_content {
  background: inherit;
  margin: 0px;
  padding: 40px;
  width: 100%;
  float: left;
}

.helpdesk_solutions .bg_card_4 .cogni_workspace_content {
  background: inherit;
  margin: 0px;
  padding: 40px;
  width: 100%;
  float: left;
}

.cards_wrapper_home {
  width: 100%;
  float: left;
  display: flex;
  justify-content: space-between;
}

.cards_wrapper_home .col-md-6 {
  flex-basis: 48.2%;
}


.helpdesk_solutions .main-slider-left h1 {
  width: 100%;
  float: left;
  text-align: center;
}

.apps_integrations_home .cc_feature_apps .cogni_fav_apps_color {
  flex-wrap: wrap;
}

.apps_integrations_home .card_office_cc {
  width: 16.66%;
  margin-bottom: 40px;
}

.apps_links_home {
  width: 100%;
  float: left;
  justify-content: center;
  display: flex;
}

.apps_links_home a:first-child {
  margin-right: 12%;
}

.apps_links_home a {
  text-align: left;
  font-family: "Roboto-Medium";
  font-size: 18px;
  color: #2e6dfa;
  padding-bottom: 0px;
  width: auto;
  float: left;
  margin-top: 0px;
  text-decoration: none;
}

.apps_links_home a:hover {
  text-decoration: none;
  color: #2e6dfa;
}

.center_image_home .main-slider-left {
  padding: 80px 10%;
  padding-bottom: 0px;
}

.center_image_home .main-slider-left h2 {
  padding: 0px;
  text-align: center;
  font-family: "Roboto-Medium";
  font-size: 40px;
  color: #333333;
  letter-spacing: -1.5;
  margin-bottom: 10px;
  width: 100%;
  float: left;
  line-height: 1.5;
}

.ahref-wrapper {
  width: 100%;
  float: left;
  text-align: center;
  margin-bottom: 30px;
  display: flex;
  justify-content: center;
}

.center_image_home .main-slider-left a {
  width: auto;
  float: left;
}

.center_image_home {
  width: 100%;
  float: left;
  padding-bottom: 80px;
}

.center_image_home .main-slider-left img {
  filter: drop-shadow(0px 3px 5px rgb(104, 103, 103, 0.1));
}

.left-whitespace {
  position: absolute;
  left: -15%;
  width: 12vw;
  background: #f7f7f7;
  height: 100%;
  top: 15%;
  opacity: 1;
  z-index: 9;
}

/* Featured Content Slider End */
/* Newsletter new Ui Start */
.header_top_red {
  color: #193a5d;
  font-family: "Roboto-Bold";
  font-size: 38px;
  text-align: left;
  margin-top: 40px;
  margin-bottom: 30px;
}

.header_top_red span {
  color: #bf3e60;
  font-family: "Roboto-Regular";
  font-size: 38px;
  text-align: left;
  margin-top: 40px;
  margin-bottom: 30px;
}

.newsletter-explorations h1.header_top_left {
  line-height: 1.29;
  letter-spacing: -1.6px;
  color: #193a5d;
  font-family: "Roboto-Medium";
  font-size: 48px;
  padding: 0 0px;
  margin-bottom: 40px;
  text-align: left;
}

.paper_blog_wrapper .statistics-boggle-content h3 span:before {
  content: "";
  border-top: 3px solid #193a5d;
  position: absolute;
  top: 0;
  width: 100%;
  float: left;
}

.paper_blog_wrapper .statistics-boggle-content h3 {
  text-align: left;
  width: 100%;
  float: left;
  padding: 0px;
  margin-bottom: 15px;
}

.paper_blog_wrapper .statistics-boggle-content h3 span {
  width: auto;
  float: left;
  font-family: "Roboto-Medium";
  font-size: 28px;
  letter-spacing: -0.2px;
  position: relative;
  color: #000;
  padding-top: 7px;
}

.newsletter_content_wrapper {
  width: 100%;
  float: left;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  flex: 0 0 100%;
}

.newsletter_content_left {
  flex: 0 0 46%;
}

.newsletter_content_right {
  flex: 0 0 46%;
}

.container_trial .statistics-boggle-content .newsletter_content_left p {
  color: #193a5d;
  font-size: 16px;
  word-break: break-word;
  margin-bottom: 30px;
  text-align: left;
  padding: 0px;
}

.container_trial .statistics-boggle-content .newsletter_content_right p {
  color: #193a5d;
  font-size: 16px;
  word-break: break-word;
  margin-bottom: 30px;
  text-align: left;
  padding: 0px;
}

.container_trial .cards_div_nesletter p {
  padding: 0px;
}

.statistics-boggle-content
  .newsletter_content_wrapper
  .newsletter_content_left
  p:last-child {
  margin-bottom: 0px;
}

.statistics-boggle-content
  .newsletter_content_wrapper
  .newsletter_content_right
  p:last-child {
  margin-bottom: 0px;
}

.statistics-boggle-content .newsletter_content_right p {
  color: #193a5d;
  font-size: 16px;
  word-break: break-word;
  margin-bottom: 30px;
  text-align: left;
}

.paper_blog_wrapper {
  margin-bottom: 80px;
  width: 100%;
  float: left;
}

.whitepaer_border {
  width: 100%;
  float: left;
  border: 3px solid #bf3e60;
}

.whitepaer_border .row {
  padding: 50px;
}

.paper_blog_wrapper .statistics-boggle-content.whitepaer_border h3 span {
  width: auto;
  float: left;
}

.newsletter_content_left ul {
  padding-left: 15px;
  margin-bottom: 30px;
  width: 100%;
  float: left;
}

.newsletter_content_left ul li {
  list-style: inherit !important;
  margin-bottom: 10px !important;
  color: #193a5d;
  font-size: 16px;
}

.statistics-boggle-content .header_top_red {
  color: #bf3e60;
  border-top: 3px solid #193a5d;
  padding-top: 10px;
  margin-top: 0px;
}

.statistics-boggle-content .header_top_left {
  line-height: 1.29;
  letter-spacing: -1.6px;
  color: #193a5d;
  font-family: "Roboto-Medium";
  font-size: 48px;
  padding: 0 0px;
  margin-bottom: 60px;
  text-align: left;
}

.cards_newsletter_wrapper {
  display: flex;
  flex: 0 0 100%;
  justify-content: space-between;
  flex-wrap: wrap;
}

.cards_div_nesletter {
  flex: 0 0 46%;
}

.cards_div_nesletter p {
  width: 100%;
  text-align: left;
  float: left;
}

.paper_blog_wrapper
  .statistics-boggle-content
  .cards_div_nesletter
  h3
  span:before {
  content: "";
  border-top: 3px solid #bf3e60;
  position: absolute;
  top: 0;
  width: 100%;
  float: left;
}

.paper_blog_wrapper .statistics-boggle-content .cards_div_nesletter h3 {
  margin-bottom: 10px;
}

.cards_div_nesletter:last-child p {
  margin-bottom: 0px;
}

.content_wrapper_office {
  width: 100%;
  float: left;
  margin-bottom: 80px;
}

.content_wrapper_bg {
  width: 100%;
  float: left;
  background: #f5f5f5 0% 0% no-repeat padding-box;
  border-radius: 20px;
  padding: 60px 80px;
  justify-content: center;
  display: flex;
  flex-wrap: wrap;
}

.content_wrapper_bg p {
  text-align: center;
  font-family: "Roboto-Regular";
  letter-spacing: 0px;
  color: #193a5d;
  font-size: 20px;
  margin-bottom: 15px;
}

.content_wrapper_bg a {
  text-align: center;
  font-family: "Roboto-Medium";
  letter-spacing: 0px;
  color: #2468f6;
  font-size: 22px;
}

.statistics-boggle-content.whitepaper-boggle-content h3 {
  width: 45%;
  float: left;
}

.statistics-boggle-content.whitepaper-boggle-content h3 span {
  width: auto;
  float: left;
}

.statistics-boggle-content.whitepaper-boggle-content .cards_div_nesletter h3 {
  width: 100%;
  float: left;
}

.statistics-boggle-content.whitepaper-boggle-content h3 {
  width: 45%;
  float: left;
}
.bg_section_knowledge.self_service_an .main-slider-left {
  padding-right: 40%;
  padding-left: 15px;
}
/* Newsletter new Ui End*/

/* Remove close mark in chrome input box*/
input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
  -webkit-appearance: none;
}
/* Remove close mark in chrome input box*/
.container_trial .solutions_form p.basic_plans{
  margin-top:40px;
  color:#000;
}
.solution_service .col-lg-6.col-md-6.col-sm-6.col-12{
  display: flex;
  /* flex-direction: column;
  overflow: hidden;
  border: none; */
}
.container_trial .solution_service p {
  flex: 1 1 auto;
  display: flex;
}